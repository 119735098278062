import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { cnpjNormalizer, cpfNormalizer } from "../../util";

class ContractAgentDescriptionEN extends Component {
  state = {
    checked1: false,
    checked2: false,
  };

  render() {
    let { contractUser } = this.props;

    if (this.state.checked1 && this.state.checked2) {
      this.props.validContractAgent(true);
    } else {
      this.props.validContractAgent(false);
    }

    const nome_do_agente = contractUser.name;
    const pessoa_física_juridica = contractUser.type === "NATURAL_PERSON" ? <FormattedMessage id="app.naturalPerson" /> : <FormattedMessage id="app.company" />;
    const cpf_cnpj = contractUser.documentType;
    const numero_cpf_cnpj =
      contractUser.type == "NATURAL_PERSON"
        ? contractUser.taxResidence == "Brasil"
          ? cpfNormalizer(contractUser.documentId)
          : contractUser.documentId
        : contractUser.taxResidence == "Brasil"
        ? cnpjNormalizer(contractUser.documentId)
        : contractUser.documentId;
    const endereco = `${contractUser.address} - ${contractUser.city} / ${contractUser.state} - ${contractUser.country}`;

    return (
      <>
        <div className="doc" style={{ textAlign: "justify" }}>
          <h4>CONTRACT FOR AUTONOMOUS SERVICE</h4>

          <h4>1. THE PARTIES</h4>
          <p>
            CLAUSE 1: Lux Capital, LLC, a private legal entity headquartered at 1603 Capitol Avenue, Ste 219 - Cheyenne, WY 82001, United States, registered under number 2020-927450, hereinafter
            referred to as CONTRACTOR,
          </p>
          <p>
            CLAUSE 2: and on the other hand, {nome_do_agente}, {pessoa_física_juridica} with {cpf_cnpj} {numero_cpf_cnpj}, residing at {endereco}, herein named CONTRACTOR, hereby decide to enter into
            an agreement for the provision of autonomous services governed by the clauses agreed upon below:
          </p>

          <h4>2. THE OBJECT</h4>
          <p>
            CLAUSE 3: The object of the present contract is the rendering of services to be done by the CONTRACTED one in favor of the CONTRACTOR, exercised by the publicity and presentation to the
            clients, demonstrating the services that are available by the CONTRACTOR, inside of its entrepreneurial capacity in the legal terms disposed by it, being exclusively about promotion to
            make known and how to make use of the services offered by the CONTRACTOR.
          </p>
          <p>
            CLAUSE 4: The rendering of the service object of this instrument will be executed in the region determined by the SERVICE PROVIDER, which shall be informed to the EMPLOYER about the choice
            of the place of performance;
          </p>

          <h4>3. THE PAYMENT</h4>
          <p>
            CLAUSE 5: For the performance of the object of this instrument, identified in Clause 3, the SERVICE PROVIDER will receive, monthly, a percentage of the volume in custody of each active
            LCContract under its responsibility.
          </p>
          <p>
            First Paragraph: The volume under custody of an LCContract corresponds to the amount contributed by the customer at the moment of its opening, as described in the TERMS AND CONDITIONS OF
            USE.
          </p>
          <p>Second Paragraph: The amount deposited by you in an LCContract comes from your personal, autonomous and individual decision to subscribe to the services provided by CONTRACTOR.</p>
          <p>
            Third Paragraph: An LCContract is considered active in the period between its activation date and termination date, i.e. the period during which it is generating increased volume for the
            customer.
          </p>
          <p>
            The value of the percentage varies according to the type of LCContract, where longer term and closed contracts (see the TERMS AND CONDITIONS OF USE for details) generate a higher value.
          </p>
          <p>
            CLAUSE 6: The payments referring to the services rendered will be made by means of credit in the account of the SERVICE PROVIDER on the platform. The amounts referring to the commission of
            the service provision will be paid in the stable coin determined according to the country of the CONTRACTED PARTY'S registration.
          </p>
          <p>CLAUSE 7: The payment of the commission will occur on the anniversary day of the LCContract to which it refers.</p>
          <p>Sole Paragraph: The anniversary day of a LCContract is the day on which it was activated, counting one anniversary every month.</p>
          <p>
            CLAUSE 8: The withdrawal of funds from the account on the platform must be carried out in accordance with the rules valid for all users of the platform, as described in the TERMS AND
            CONDITIONS OF USE.
          </p>

          <h4>4. THE CONTRACTOR'S OBLIGATIONS</h4>
          <p>CLAUSE 9: The contracting party is obligated to:</p>
          <p>a) provide information, clarifications and training for the good development of the activities object of this contractual act;</p>
          <p>b) provide technical support necessary for the follow-up of the activity that is the object of this contract;</p>
          <p>
            c) to supervise the activity of the SERVICE PROVIDER and to propitiate that the same develops the activities in accordance with the normative ones and orientation provided by the
            CONTRACTING PARTY;
          </p>
          <p>d) realize the payment of the rendered services observed in the clauses 5ª to 7ª of the present term.</p>
          <p>
            e) previously notify the SERVICE PROVIDER of the meetings, courses or trainings to be carried out by the EMPLOYER or by whom it, exclusively, indicates apt for such, with 5 days in
            advance.
          </p>

          <h4>5. THE CONTRACTOR'S OBLIGATIONS</h4>
          <p>CLAUSE 10: The contractor is obligated to:</p>
          <p>
            a) perform the service rendering object of this contract in accordance with the norms, information and training provided by the CONTRACTING PARTY, or by whom it, exclusively, indicates apt
            for such;
          </p>
          <p>b) to exert, in a very personal way, the rendering of services object of this contract;</p>
          <p>c) to give, in a clear and objective way, information and orientation to the customers about the functionality and services of the CONTRACTING PARTY;</p>
          <p>d) to supply, to inform, to fill out adequately to the platform the data of the respective customers to the CONTRACTING company.</p>
          <p>e) supply to the CONTRACTING PARTY, whenever requested, in the stated period of 48 hours, the information referring to the rendering of services of this contract.</p>
          <p>f) participate in the meetings, courses or trainings provided by the CONTRACTING company, previously scheduled, or by whom it, exclusively, indicates to be able to do it.</p>
          <p>
            g) bear the costs of travel, lodging, transportation, insurance or any other necessary expenses to attend the meetings, courses and trainings previously scheduled by the CONTRACTING PARTY,
            or by whom it, exclusively, indicates that it is apt for such.
          </p>
          <p>h) properly answer the declaration of the fiscal taxes of the values received in benefit of the rendering of services.</p>

          <h4>6. CONTRACTUAL VALIDITY</h4>
          <p>CLAUSE 11: The present service provision contract is in effect from the date of its signature, becoming effective for an undetermined period.</p>

          <h4>7. CONTRACTUAL RESCISSION</h4>
          <p>
            CLAUSE 12: The contract can be terminated without cause by both parties and may be requested with a prior notice of 30 (thirty) days, by means of written notification, except when
            non-systemic, governmental, or regulatory and inspection agency interruptions, which may change the modus operandi of the market in question.
          </p>
          <p>CLAUSE 13: The contract can also be rescinded by right and without prior notification, for a just reason, understood as</p>
          <p>
            Paragraph One: Unilaterally, by the innocent party, in the event of non-compliance with any of the obligations assumed in this contract, without prejudice to indemnification, by the
            breaching party to the innocent party, for any losses and damages to which the breaching party gives cause;
          </p>
          <p>
            Second Paragraph: In case of request, decree or homologation of judicial or extrajudicial recovery, bankruptcy, liquidation, dissolution or unequivocal situation of insolvency of the other
            party;
          </p>
          <p>Third Paragraph: The occurrence of a fortuitous case or force majeure is proven.</p>
          <p>
            Fourth Paragraph: In any of these hypotheses of contractual termination, the parties are guaranteed a settlement of accounts for the services already rendered, up to the last effective
            date of services rendered.
          </p>

          <h4>8. THE LABOR RESPONSIBILITY</h4>
          <p>
            CLAUSE 14: It is hereby stipulated that, by force of the present contract, there is no employment relationship or responsibility between the EMPLOYER and the SERVICE PROVIDER for the
            execution of the services, and the SERVICE PROVIDER is solely and exclusively responsible for all obligations and charges resulting from the current legislation, whether labor, social
            security or any other matter.
          </p>
          <p>CLAUSE 15: The CONTRACTOR is not responsible for any labor debts, taxes or any other that the CONTRACTED one possesses. These are of its exclusive and non-transferable responsibility;</p>
          <p>
            CLAUSE 16: Eventual labor convictions of the SERVICE PROVIDER resulting from the legal relationship established herein between the parties, may be deducted from the monthly payment due to
            the SERVICE PROVIDER. In case the amount of convictions exceeds the amounts due by the, the SERVICE PROVIDER shall reimburse the EMPLOYER the amount of the difference, and the sums may be
            deducted from future payments.
          </p>

          <h4>9. THE TAX RESPONSIBILITY</h4>
          <p>
            CLAUSE 17: In case the CONTRACTING PARTY is obliged to pay or to indemnify the amount referring to taxes not paid by the CONTRACTED one, the latter will be obliged to reimburse all the
            spent amounts, including eventual procedural costs and attorney's fees, within 5 days of the effective payment.
          </p>

          <h4>10. CIVIL RESPONSIBILITY</h4>
          <p>
            CLAUSE 18: The HIRED shall immediately repair any error, loss or damage resulting from the non-fulfillment and/or performance of the contractual obligations in disagreement with this
            contract, being obliged to reimburse the CONTRACTOR or third parties damaged.
          </p>

          <h4>11. CONFIDENTIALITY</h4>
          <p>
            CLAUSE 19: The contracting parties recognize that the bond stipulated in this contract is based on a relationship of fidelity and reciprocal good faith and are henceforth aware that, in
            the exercise of their functions, they may have access to information and materials that are not known to the general public and that the disclosing party considers to be its exclusive
            property and of a confidential nature and, in case of request of any informative or confidential material related to the activities mentioned in this contract, it is the sole
            responsibility of the CONTRACTING PARTY to provide, at its own discretion, the CONTRACTING PARTY, exempting before authorities and other governmental bodies the responsibility of the
            CONTRACTED PARTY to provide any informative material or data, considering that it is a confidential subject.
          </p>
          <p>
            CLAUSE 20: It is forbidden for any of the parts to use, in own benefit or of third parties, any privileged information for the development of equal or similar activity to the one developed
            by the other part, for the equal or longer period of 5 (five) years, when the contract is terminated.
          </p>
          <p>CLAUSE 21: The obligation of confidentiality established in this clause will last after the termination of this contract, for a period of ten (10) years.</p>
          <p>
            CLAUSE 22: All the negotiations, negotiations, contracts, manuals, circulars, notifications, trainings, meetings, certificates, accounting documents, or any information regarding the
            activity developed by the CONTRACTOR are strictly confidential, and cannot be disclosed by any means, media or under any justification, except those foreseen in the Law, under penalty of
            contractual fine, stipulated in this instrument.
          </p>
          <p>
            CLAUSE 23: The present contract and its annexes, if any, also become confidential information and therefore its existence and content may not be revealed to third parties, except by
            express authorization of the CONTRACTING PARTY.
          </p>
          <p>
            CLAUSE 24: In case of doubt about the confidentiality of certain information, the CONTRACTED one shall keep it in absolute secrecy until the CONTRACTOR expressly manifests itself about it.
          </p>
          <p>CLAUSE 25: The non-observance of the confidentiality clauses will imply in civil and criminal responsibility by the parties, which may be settled and verified in court.</p>

          <h4>12. OF THE ARBITRATION COURT</h4>
          <p>
            CLAUSE 26: This contract shall be governed by the laws of the jurisdiction of the countries where this transaction takes place and any dispute shall be resolved under the ICC Brazil rules
            for arbitration, unless the aggrieved party takes legal action in a court of jurisdiction.
          </p>
          <p>
            CLAUSE 27: The contracting parties acknowledge and agree that any dispute arising out of the application of this agreement shall be settled primarily amicably, unless the arbitration
            procedure cannot be followed.
          </p>
          <p>CLAUSE 28: Additionally, the parties may define the seat of the arbitration, the language, the number of arbitrators, the form of costs, among others.</p>

          <h4>13. GENERAL PROVISIONS</h4>
          <p>
            CLAUSE 29: The tolerance by any of the parties, regarding the compliance with the obligations of the other party, will not be considered novation or forgiveness, and the clauses of this
            contract remain in full force, as provided herein.
          </p>
          <p>CLAUSE 30: This contract and the rights and obligations hereunder may not be assigned, transferred, or subrogated by any party without the prior written consent of the other parties;</p>
          <p>
            And for being thus just and contracted, the parties sign this instrument in two copies of equal content and form, before the undersigned witnesses, together with the professional attorney
            identified with his/her credentials.
          </p>
          <p>Document version: 2.1</p>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default connect(mapStateToProps, actions)(ContractAgentDescriptionEN);
