import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { cnpjNormalizer, cpfNormalizer } from "../../util";

class ContractAgentDescriptionES extends Component {
  state = {
    checked1: false,
    checked2: false,
  };

  render() {
    let { contractUser } = this.props;

    if (this.state.checked1 && this.state.checked2) {
      this.props.validContractAgent(true);
    } else {
      this.props.validContractAgent(false);
    }

    const nome_do_agente = contractUser.name;
    const pessoa_física_juridica = contractUser.type === "NATURAL_PERSON" ? <FormattedMessage id="app.naturalPerson" /> : <FormattedMessage id="app.company" />;
    const cpf_cnpj = contractUser.documentType;
    const numero_cpf_cnpj =
      contractUser.type == "NATURAL_PERSON"
        ? contractUser.taxResidence == "Brasil"
          ? cpfNormalizer(contractUser.documentId)
          : contractUser.documentId
        : contractUser.taxResidence == "Brasil"
        ? cnpjNormalizer(contractUser.documentId)
        : contractUser.documentId;
    const endereco = `${contractUser.address} - ${contractUser.city} / ${contractUser.state} - ${contractUser.country}`;

    return (
      <>
        <div className="doc" style={{ textAlign: "justify" }}>
          <h4>CONTRATO DE PRESTACIÓN DE SERVICIOS AUTÓNOMOS</h4>

          <h4>1. LAS PARTES</h4>
          <p>
            CLÁUSULA 1: Lux Capital, LLC., persona jurídica de derecho privado domiciliada en 1603 Capitol Avenue, Ste 219 - Cheyenne, WY 82001, United States, registrada bajo el no.
            2020-927450, en adelante “LA CONTRATANTE”,
          </p>
          <p>
            y, por otro lado, {nome_do_agente}, {pessoa_física_juridica} con {cpf_cnpj} {numero_cpf_cnpj}, domiciliado en {endereco}, en adelante “EL CONTRATISTA”, deciden celebrar un contrato de
            prestación de servicios autónomos, regido por las cláusulas a continuación:
          </p>

          <h4>2. EL OBJETO</h4>
          <p>
            CLÁUSULA 3: El objeto del presente contrato es la prestación de servicios que debe realizar la CONTRATISTA a favor de la CONTRATANTE, ejercida por la divulgación y presentación a los
            clientes de los servicios disponibles la CONTRATANTE, dentro de su capacidad empresarial, en los términos legales que se le proporcionará, tratándose exclusivamente de promoción para hacer
            conocer y cómo utilizar los servicios ofrecidos por la CONTRATANTE.
          </p>
          <p>
            CLÁUSULA 4: La prestación del servicio objeto de este instrumento se realizará en la región determinada por la CONTRATISTA, que deberá ser informada a la CONTRATISTA sobre la elección del
            lugar de actuación;
          </p>

          <h4>3. EL PAGO</h4>
          <p>
            CLÁUSULA 5: Para el cumplimiento del objeto de este instrumento, identificado en la Cláusula 3, la CONTRATISTA recibirá, mensualmente, un porcentaje del importe custodiado de cada
            LCContract activo bajo su responsabilidad.
          </p>
          <p>
            Párrafo uno: El importe custodiado de un LCContract corresponde al importe del cliente en el momento de la apertura del contrato, como se describe en los TÉRMINOS Y CONDICIONES DE USO.
          </p>
          <p>
            Párrafo dos: El importe aportado por el cliente en un LCContract proviene de una decisión personal, autónoma e individual del cliente, que desea anotarse a los servicios prestados por la
            CONTRATISTA.
          </p>
          <p>
            Párrafo tres: Un LCContract se considera activo en el período entre su fecha de activación y su fecha de finalización, es decir, el período mientras se está generando nuevos importes del
            cliente.
          </p>
          <p>
            El valor porcentual varía en función del tipo de LCContract. Los contratos más largos y cerrados (consulte los TÉRMINOS Y CONDICIONES DE USO para más detalles) generan un valor más alto.
          </p>
          <p>
            CLÁUSULA 6: Los pagos referentes a la prestación de los servicios realizados se realizarán a través del crédito en la cuenta de la CONTRATISTA en la plataforma. Los montos relacionados con
            la prestación del servicio se pagarán en la moneda determinada de acuerdo con el país del registro de la CONTRATISTA.
          </p>
          <p>CLÁUSULA 7: El pago de la comisión tendrá lugar en el día del aniversario de dicho LCContract.</p>
          <p>Párrafo único: El aniversario de un LCContract es el día en que se activó, cumpliendo un aniversario cada mes.</p>
          <p>
            CLÁUSULA 8: El saque de las sumas de la cuenta en la plataforma debe realizarse de acuerdo con las normas válidas para todos los, como se describe en los TÉRMINOS Y CONDICIONES DE USO.
          </p>

          <h4>4. LAS OBLIGACIONES LA CONTRATANTE</h4>
          <p>La CONTRATANTE está obligada a:</p>
          <p>a) Proveer información, aclaración y capacitación para el correcto desarrollo de las actividades sujetas a este acto contratual; </p>
          <p>b) Proveer el apoyo técnico necesario para el seguimiento de la actividad sujeta a este contrato; </p>
          <p>c) Supervisar la actividad de la CONTRATISTA y hacer que desarrolle las actividades de conformidad con las regulaciones y orientaciones proporcionadas por la CONTRATANTE;</p>
          <p>d) Realizar el pago de los servicios prestados de conformidad con las disposiciones de las cláusulas 5 a 7 de este acuerdo.</p>
          <p>e) Notificar a la CONTRATISTA sobre las reuniones, cursos o entrenamientos realizados por la CONTRATANTE o por quien, exclusivamente, pueda hacerlo, con antelación de 5 días.</p>

          <h4>5. LAS OBLIGACIONES DEL CONTRATISTA</h4>
          <p>CLÁUSULA 10: Queda obligado a:</p>
          <p>
            a) realizar la prestación de servicio objeto de este contrato de conformidad con las normas, informaciones y capacitación proporcionadas por la CONTRATANTE o por quien, exclusivamente,
            esté capacitada para ello;
          </p>
          <p>b) ejercer, de manera personalísima, la prestación del servicio objeto de este contrato;</p>
          <p>c) proporcionar de forma clara y objetiva informaciones y orientación a los clientes sobre la funcionalidad y los servicios de la empresa CONTRATANTE;</p>
          <p>d) facilitar, informar y completar adecuadamente la plataforma con los datos de los respectivos clientes a la empresa CONTRATANTE.</p>
          <p>e) proporcionar a la CONTRATANTE, siempre que lo solicite, en un plazo de 48 horas, la información referente a la prestación de servicios de este contrato.</p>
          <p>f) participar en las reuniones, cursos o entrenamientos impartidos por la empresa CONTRATANTE, previamente programados, o por quien, exclusivamente, esta lo indique.</p>
          <p>
            g) asumir los gastos de viaje, alojamiento, transporte, seguros o cualquier otro gasto necesario para la atención a sus clientes, así como la asistencia a reuniones, cursos y
            entrenamientos previamente programados por la CONTRATANTE o por quienes, exclusivamente, lo indiquen.
          </p>
          <p>h) responder adecuadamente la declaración de impuestos de las sumas recibidas por la prestación del servicio realizado.</p>

          <h4>6. EL PLAZO CONTRACTUAL</h4>
          <p>CLÁUSULA 11: Este contrato de servicio entra en vigor en la fecha de su firma, y estará en vigor por un plazo indefinido.</p>

          <h4>7. LA RESCISIÓN CONTRACTUAL</h4>
          <p>
            CLÁUSULA 12: La rescisión sin motivo del contrato es facultada a ambas las partes y puede solicitarse con 30 (treinta) días de antelación, mediante notificación escrita, excepto cuando
            haya interrupciones no sistémicas, gubernamentales o de órganos reguladores y de inspección que puedan cambiar el modus operandi del mercado.
          </p>
          <p>CLÁUSULA 13: El contrato también puede rescindirse en su totalidad y sin notificación previa, por una buena razón, entendida como:</p>
          <p>
            Párrafo uno: Unilateralmente, por la parte inocente, en caso de incumplimiento de cualquiera de las obligaciones asumidas en este contrato, sin perjuicio de la indemnización, por parte de
            la parte infractora a los inocentes, por cualquier pérdida y daño causado por la parte infractora;
          </p>
          <p>
            Párrafo dos: En el caso de una solicitud, decreto o aprobación de la recuperación judicial o extrajudicial, quiebra, liquidación, disolución o situación de insolvencia inequívoca de la
            otra parte;
          </p>
          <p>Párrafo tres: Si comprobado que sucedió algo fortuito o de fuerza mayor.</p>
          <p>
            Párrafo cuatro: En cualquiera de estos casos de rescisión contractual, se garantiza a las partes el acierto de cuentas correcto de los servicios ya prestados hasta la última fecha efectiva
            de los servicios prestados.
          </p>

          <h4>8. RESPONSABILIDAD LABORAL</h4>
          <p>
            CLÁUSULA 14: En este sentido, se estipula que, en virtud de este contrato, no se establece ningún vínculo laboral o de responsabilidad entre la CONTRATANTE y la CONTRATISTA para el
            desempeño de los servicios. La CONTRATISTA es la única y exclusiva responsable por todas las acciones y cargos derivados de la legislación vigente, ya sean laborales, de seguridad social o
            de cualquier otro, obligándose así la CONTRATISTA a cumplir con las disposiciones legales respecto a la remuneración del trabajo que utiliza y otras obligaciones y cargos de cualquier
            naturaleza.
          </p>
          <p>CLÁUSULA 15: La CONTRATANTE no es responsable de ninguna deuda laboral, fiscal o cualquier otra deuda que tenga la Compañía. Estas son su responsabilidad única e intransferible;</p>
          <p>
            CLÁUSULA 16: Eventuales condenas laborales de la CONTRATANTE, resultantes de la relación legal establecida en el presente documento entre las partes, podrán ser descontadas del pago
            mensual adeudado a la CONTRATISTA. Si el valor de las condenas excede las sumas adeudadas por la CONTRATISTA, la CONTRATISTA pagará a la CONTRATANTE la diferencia. Los montos también
            pueden ser descontados de pagos futuros.
          </p>

          <h4>9. RESPONSABILIDAD TRIBUTARIA</h4>
          <p>
            CLÁUSULA 17: Si a la CONTRATANTE se le obligan pagar o indemnizar sumas relacionadas a impuestos no pagados por la CONTRATISTA, este último estará obligado a reembolsar todas las
            cantidades pagadas, incluidos los costos procesales y honorarios de abogados, dentro de los 5 días posteriores al pago.
          </p>

          <h4>10. RESPONSABILIDAD CIVIL</h4>
          <p>
            CLÁUSULA 18: La CONTRATISTA deberé reparar inmediatamente cualquier error, pérdida o daño resultante del incumplimiento y/o el cumplimiento de las obligaciones contractuales en
            desconformidad con el presente contrato, obligándose a indemnizar a la CONTRATANTE o a terceros perjudicados.
          </p>

          <h4>11. CONFIDENCIALIDAD</h4>
          <p>
            CLÁUSULA 19: Las partes contratantes reconocen que el vínculo estipulado en este acuerdo se basa en una relación de fidelidad y buena fe mutua. Son conscientes de que, en el desempeño de
            sus funciones, pueden tener acceso a información y materiales que no son conocidos por el público en general y que la parte reveladora considera de su propiedad exclusiva y confidencial.
            Asimismo, en caso de solicitud de cualquier información o material confidencial relacionado con las actividades mencionadas en este acuerdo, es el único responsable de proporcionar, a
            sudiscreción, a la empresa CONTRATANTE, eximiendo ante las autoridades y otros organismos gubernamentales la responsabilidad de la CONTRATANTE de proveer cualquier material o datos de
            información, teniendo en cuenta el tema de la confidencialidad.
          </p>
          <p>
            CLÁUSULA 20: Se prohíbe a las partes utilizar en beneficio propio o de terceros cualquier información privilegiada para el desarrollo de una actividad igual o similar a la desarrollada por
            la otra parte por un período igual o superior a cinco (5) años desde el cierre el contrato.
          </p>
          <p>CLÁUSULA 21: La obligación de sigilo establecida en esta cláusula durará después de la extinción de este contrato por un período de diez (10) años.</p>
          <p>
            CLÁUSULA 22: Todas las tratativas, negociaciones, contratos, manuales, circulares, notificaciones, entrenamientos, reuniones, certificados, documentos contables o cualquier información
            sobre la actividad desarrollada por la CONTRATANTE son estrictamente confidenciales y no pueden ser divulgados por ningún medio, medio de comunicación o bajo ninguna justificación, excepto
            según lo dispuesto por la ley, bajo la penalidad de la aplicación de una multa contractual estipulada en este instrumento.
          </p>
          <p>
            CLÁUSULA 23: El presente contrato y sus anexos, en su caso, también se convierten en información confidencial y, por lo tanto, su existencia y su contenido no pueden ser revelados a
            terceros, sino mediante autorización expresa de la CONTRATANTE.
          </p>
          <p>
            CLÁUSULA 24: En caso de duda sobre la confidencialidad de una determinada información, la CONTRATISTA la mantendrá en absoluto sigilo hasta que la CONTRATANTE se manifieste expresamente
            sobre el tema.
          </p>
          <p>CLÁUSULA 25: El incumplimiento de las cláusulas de confidencialidad resultará en responsabilidad civil y penal por las partes, que podrá resolverse y apurarse en los tribunales.</p>

          <h4>12. EL JUICIO ARBITRAL</h4>
          <p>
            CLÁUSULA 26: Este acuerdo es aplicable de conformidad con las leyes jurisdiccionales de los países donde se lleva a cabo esta transacción y cualquier disputa se resolverá bajo las reglas
            de arbitraje de ICC Brasil, a menos que la parte perjudicada tome acciones legales en un tribunal de jurisdicción.
          </p>
          <p>
            CLÁUSULA 27: Los contratistas reconocen y acuerdan que cualquier disputa en la aplicación de este acuerdo se resolverá, en primer lugar, amistosamente. Solo si no es posible, el
            procedimiento de arbitraje debe ser llevado a cabo.
          </p>
          <p>CLÁUSULA 28: Además de ello, las partes pueden definir el lugar del arbitraje, el idioma, el número de árbitros y la forma de costo entre otros.</p>

          <h4>13. DISPOSICIONES GENERALES</h4>
          <p>
            CLÁUSULA 29: La tolerancia de cualquiera de las partes respecto al cumplimiento de las obligaciones de la otra parte no se considerará una novación o indulto. Las disposiciones de este
            contrato siguen en pleno vigor, como se establece en el presente documento.
          </p>
          <p>
            CLÁUSULA 30: El presente contrato, los derechos y obligaciones que surjan del mismo no podrán ser cedidos, transferidos o subrogados por ninguna de las partes sin el consentimiento previo
            por escrito de las demás partes;
          </p>
          <p>
            Y, debido a que por lo tanto son justos y contratados, las partes firman este instrumento de dos copias de igual contenido y forma ante los testigos que también subscriben, junto a un
            profesional abogado acreditado con su credencial.
          </p>
          <p>Document version: 2.1</p>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default connect(mapStateToProps, actions)(ContractAgentDescriptionES);
