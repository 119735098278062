import { Checkbox } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../actions";
import { cnpjNormalizer, cpfNormalizer } from "../../util";

class ContractAgentManagerDescriptionES extends Component {
  state = {
    checked1: false,
    checked2: false,
  };

  render() {
    let { contractUser, contractAgent } = this.props;

    if (this.state.checked1 && this.state.checked2) {
      this.props.validContractAgent(true);
    } else {
      this.props.validContractAgent(false);
    }

    const nome_do_gestor = contractUser.name;
    const pessoa_física_juridica =
      contractUser.type === "NATURAL_PERSON" ? (
        <FormattedMessage id="app.naturalPerson" />
      ) : (
        <FormattedMessage id="app.company" />
      );
    const cpf_cnpj = contractUser.documentType;
    const numero_cpf_cnpj =
      contractUser.type == "NATURAL_PERSON"
        ? contractUser.taxResidence == "Brasil"
          ? cpfNormalizer(contractUser.documentId)
          : contractUser.documentId
        : contractUser.taxResidence == "Brasil"
        ? cnpjNormalizer(contractUser.documentId)
        : contractUser.documentId;
    const endereco = `${contractUser.address} - ${contractUser.city} / ${contractUser.state} - ${contractUser.country}`;
    const managerComission = (contractAgent.managerComission * 100).toFixed(2);

    return (
      <>
        <div className="doc" style={{ textAlign: "justify" }}>
          <p class="c0">
            <span class="c1">CONTRATO DE PRESTACIÓN DE SERVICIO AUTÓNOMO CON SUBREPRESENTACIÓN ES</span>
          </p>
          <p class="c0">
            <span class="c3 c1">1. </span>
            <span class="c3 c1 c4">LAS PARTES</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 1:</span>
            <span class="c1">
              &nbsp;Lux Capital, LLC., persona jurídica de derecho privado domiciliada en 1603 Capitol
            </span>
            <span>&nbsp;</span>
            <span class="c1">Avenue,</span>
            <span>&nbsp;</span>
            <span class="c1">Ste</span>
            <span>&nbsp;</span>
            <span class="c2 c1">
            219 - Cheyenne, WY 82001, United States, registrada bajo el no. 2020-927450, en adelante “LA
              CONTRATANTE”,
            </span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 2:</span>
            <span class="c1">&nbsp;y, por otro lado, </span>
            <span class="c6 c3 c1">{nome_do_gestor}</span>
            <span class="c1">, </span>
            <span class="c6 c3 c1">{pessoa_física_juridica}</span>
            <span class="c1">&nbsp;con </span>
            <span class="c6 c3 c1">{cpf_cnpj}</span>
            <span class="c1">&nbsp;</span>
            <span class="c6 c3 c1">{numero_cpf_cnpj}</span>
            <span class="c1">, domiciliado en </span>
            <span class="c3 c1 c6">{endereco}</span>
            <span class="c2 c1">
              , en adelante “EL CONTRATISTA”, deciden celebrar un contrato de prestación de servicios autónomos, regido
              por las cláusulas a continuación:
            </span>
          </p>
          <p class="c0">
            <span class="c4 c3 c1">2. EL OBJETO</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 3:</span>
            <span class="c1">
              &nbsp;El objeto del presente contrato es la prestación de servicios que debe realizar la CONTRATISTA a
              favor de la CONTRATANTE, ejercida por la divulgación y presentación a los clientes de los servicios
            </span>
            <span>&nbsp;disponibles </span>
            <span class="c1">la CONTRATANTE</span>
            <span>
              , dentro de su capacidad empresarial, en los términos legales que se le proporcionará, tratándose
              exclusivamente de promoción para hacer conocer y cómo utilizar los servicios ofrecidos por{" "}
            </span>
            <span class="c2 c1">la CONTRATANTE.</span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Párrafo uno: Por la forma estipulada en este contrato, la empresa CONTRATISTA utilizará el modelo de
              subrrepresentación para realizar los servicios dispuestos en el objeto de este término.
            </span>
          </p>
          <p class="c0">
            <span class="c1">Párrafo dos: los subrepresentantes</span>
            <span>&nbsp;</span>
            <span class="c1">se denominarán AGENTES y estarán sujetos </span>
            <span>al CONTRATO DE PRESTACIÓN DE </span>
            <span class="c1">
              SERVICIO AUTÓNOMO, el cual lo deben aceptar en una pantalla dentro de la plataforma de la CONTRATANTE.
            </span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 4:</span>
            <span class="c2 c1">
              &nbsp;La prestación del servicio objeto de este instrumento se realizará en la región determinada por la
              CONTRATISTA, que deberá ser informada a la CONTRATISTA sobre la elección del lugar de actuación;
            </span>
          </p>
          <p class="c0">
            <span class="c4 c3 c1">3. SUBREPRESENTACIÓN</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 5:</span>
            <span class="c1">
              &nbsp;La CONTRATANTE queda autorizada a utilizar la subrepresentación para llevar a cabo la actividad
              objeto de este contrato. Le toca únicamente y exclusivamente a la CONTRATISTA la elección, selección,
              relacionamiento, información, mantenimiento, administración, gestión, organización y coordinación{" "}
            </span>
            <span>de dichos </span>
            <span class="c1">subrepresentantes, permitiéndoles </span>
            <span>realizar las actividades descritas en la Cláusula </span>
            <span class="c1">3</span>
            <span>&nbsp;en favor </span>
            <span class="c1">de la CONTRATISTA.</span>
          </p>
          <p class="c0">
            <span class="c1">
              Párrafo uno: El uso de la marca, de los productos, de los servicios, de los documentos y la provisión de
              capacitación permanecen con toda la exclusividad de la CONTRATISTA;
            </span>
          </p>
          <p class="c0">
            <span class="c1">Párrafo dos: Queda definida en este contrato </span>
            <span>y estipulada la responsabilidad de la </span>
            <span class="c1">CONTRATISTA</span>
            <span>&nbsp;por todos los servicios y la comunicación con sus </span>
            <span class="c1">subrepresentados.</span>
            <span>&nbsp;La empresa </span>
            <span class="c1">CONTRATANTE </span>
            <span>es </span>
            <span class="c1">responsable de cumplir con las obligaciones</span>
            <span>&nbsp;mencionadas en el presente documento en virtud únicamente de</span>
            <span class="c2 c1">&nbsp;la CONTRATISTA.</span>
          </p>
          <p class="c0">
            <span class="c4 c3 c1">4. EL PAGO</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 6:</span>
            <span class="c1">
              &nbsp;Para el cumplimiento del objeto de este instrumento, identificado en la Cláusula 3, la CONTRATISTA
            </span>
            <span>&nbsp;</span>
            <span class="c1">recibirá, mensualmente, un porcentaje del volumen custodiado de cada LCContract</span>
            <span>&nbsp;</span>
            <span class="c1">activo bajo la responsabilidad de uno </span>
            <span>de sus </span>
            <span class="c2 c1">subrepresentantes.</span>
          </p>
          <p class="c0">
            <span class="c1">a) El valor porcentual es</span>
            <span>&nbsp;del </span>
            <span class="c2 c1">{managerComission}%.</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 7:</span>
            <span class="c1">
              &nbsp;Los pagos referentes a la prestación de los servicios realizados se realizarán a través del crédito
              en la cuenta de la CONTRATISTA en la plataforma. Los montos relacionados con la prestación del servicio se
              pagarán en la{" "}
            </span>
            <span>moneda </span>
            <span class="c1">determinada</span>
            <span>&nbsp;de acuerdo con el país del registro de </span>
            <span class="c2 c1">la CONTRATISTA.</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 8:</span>
            <span class="c2 c1">
              &nbsp;El pago de la comisión tendrá lugar en el día del aniversario de dicho LCContract.
            </span>
          </p>
          <p class="c0">
            <span class="c1">Párrafo único: El aniversario de un LCContract</span>
            <span>&nbsp;</span>
            <span class="c2 c1">es el día en que se activó, cumpliendo un aniversario cada mes.</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 9:</span>
            <span class="c1">&nbsp;El saque de las sumas</span>
            <span>&nbsp;de la cuenta en la plataforma debe realizarse de acuerdo con las normas </span>
            <span class="c1">válidas para todos los,</span>
            <span>&nbsp;como se describe en los TÉRMINOS Y CONDICIONES</span>
            <span class="c2 c1">&nbsp;DE USO.</span>
          </p>
          <p class="c0">
            <span class="c4 c3 c1">5. LAS OBLIGACIONES LA CONTRATANTE</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 10:</span>
            <span class="c2 c1">&nbsp;La CONTRATANTE está obligada a:</span>
          </p>
          <p class="c0">
            <span class="c1">a)</span>
            <span>&nbsp;</span>
            <span class="c1">Proveer</span>
            <span>&nbsp;</span>
            <span class="c1">
              información, aclaración y capacitación para el correcto desarrollo de las actividades sujetas a este acto
              contratual;
            </span>
          </p>
          <p class="c0">
            <span class="c1">b) Proveer</span>
            <span>&nbsp;el apoyo </span>
            <span class="c1">técnico</span>
            <span>&nbsp;necesario para el seguimiento de la actividad sujeta a</span>
            <span class="c1">&nbsp;este contrato;</span>
            <span>&nbsp;</span>
          </p>
          <p class="c0">
            <span class="c1">
              c) Supervisar la actividad de la CONTRATISTA y hacer que desarrolle las actividades de conformidad con las
              regulaciones y orientaciones proporcionadas por la CONTRATANTE;
            </span>
            <span>&nbsp;</span>
            <span class="c1">&nbsp;</span>
            <span>&nbsp;</span>
          </p>
          <p class="c0">
            <span class="c1">
              d) Realizar el pago de los servicios prestados de conformidad con las disposiciones de las cláusulas 5 a 7
              de este acuerdo.
            </span>
            <span>&nbsp;</span>
          </p>
          <p class="c0">
            <span class="c1">e) Notificar a la CONTRATISTA sobre las reuniones, cursos o entrenamientos </span>
            <span>realizados por </span>
            <span class="c1">la CONTRATANTE</span>
            <span>&nbsp;o </span>
            <span class="c1">por quien, exclusivamente, pueda hacerlo,</span>
            <span class="c2 c9">&nbsp;con antelación de 5 días.</span>
          </p>
          <p class="c0">
            <span class="c4 c3 c1">6 LAS OBLIGACIONES DEL CONTRATISTA</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 11:</span>
            <span class="c2 c1">&nbsp;Queda obligado a:</span>
          </p>
          <p class="c0">
            <span class="c1">
              a) realizar la prestación de servicio objeto de este contrato de conformidad con las normas, informaciones{" "}
            </span>
            <span>y capacitación proporcionadas por la CONTR</span>
            <span class="c1">ATANTE</span>
            <span>&nbsp;</span>
            <span class="c1">&nbsp;</span>
            <span>o por </span>
            <span class="c1">quien, exclusivamente, esté capacitada para ello;</span>
            <span>&nbsp;</span>
          </p>
          <p class="c0">
            <span class="c1">
              b) ejercer, de manera personalísima, la prestación del servicio objeto de este contrato, además de exigir
              lo mismo de su subrepresentado.
            </span>
            <span class="c2 c9">&nbsp;</span>
          </p>
          <p class="c0">
            <span class="c1">
              c) proporcionar de forma clara y objetiva informaciones y orientación a los clientes sobre la
            </span>
            <span>&nbsp;funcionalidad y los </span>
            <span class="c1">servicios</span>
            <span>&nbsp;de la</span>
            <span class="c1">&nbsp;empresa CONTRATANTE;</span>
            <span>&nbsp;</span>
          </p>
          <p class="c0">
            <span class="c1">
              d) facilitar, informar y completar adecuadamente la plataforma con los datos de los respectivos clientes a
              la empresa CONTRATANTE.
            </span>
            <span>&nbsp;</span>
          </p>
          <p class="c0">
            <span class="c1">
              e) proporcionar a la CONTRATANTE, siempre que lo solicite, en un plazo de 48 horas, la información
              referente a la prestación{" "}
            </span>
            <span>de servicios de este </span>
            <span class="c1">contrato.</span>
            <span>&nbsp;</span>
          </p>
          <p class="c0">
            <span class="c1">
              f) participar en las reuniones, cursos o entrenamientos impartidos por la empresa CONTRATANTE, previamente
              programados,
            </span>
            <span>&nbsp;</span>
            <span class="c1">o por</span>
            <span>&nbsp;</span>
            <span class="c1">quien, exclusivamente, esta lo indique.</span>
            <span>&nbsp;</span>
          </p>
          <p class="c0">
            <span class="c1">g) asumir los gastos de viaje, alojamiento, transporte,</span>
            <span>&nbsp;seguros o cualquier otro gasto necesario para la atención a sus clientes, así como la</span>
            <span class="c1">
              &nbsp;asistencia a reuniones, cursos y entrenamientos previamente programados por la CONTRATANTE
            </span>
            <span>&nbsp;</span>
            <span class="c1">o por </span>
            <span>quienes</span>
            <span class="c1">, exclusivamente, lo indiquen.</span>
            <span>&nbsp;</span>
            <span class="c1">&nbsp;</span>
            <span>&nbsp;</span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              h) responder adecuadamente la declaración de impuestos de las sumas recibidas por la prestación del
              servicio realizado.
            </span>
          </p>
          <p class="c0">
            <span class="c1">i) informar, comunicar, transmitir, instruir, aclarar, advertir y orientar </span>
            <span>a los </span>
            <span class="c1">subrepresentados</span>
            <span>&nbsp;de manera clara y correcta todos los procedimientos que deban emplearse para llevar a</span>
            <span class="c1">&nbsp;cabo el objeto de este contrato,</span>
            <span>&nbsp;tal y como establece la </span>
            <span class="c1">CONTRATANTE</span>
            <span>. </span>
          </p>
          <p class="c0">
            <span class="c1">j) mantener un</span>
            <span>&nbsp;contrato de prestación de servicios vigente directamente con</span>
            <span class="c1">&nbsp;los</span>
            <span>&nbsp;</span>
            <span class="c1">subrepresentados, estipulando</span>
            <span>&nbsp;</span>
            <span class="c1">sus responsabilidades y obligaciones con</span>
            <span>&nbsp;</span>
            <span class="c1">esta</span>
            <span>&nbsp;y los respectivos servicios proveídos por</span>
            <span class="c1">&nbsp;</span>
            <span>la </span>
            <span class="c1">CONTRATANTE.</span>
            <span>&nbsp;</span>
          </p>
          <p class="c0">
            <span class="c1">k) debe informar a los</span>
            <span>&nbsp;</span>
            <span class="c1">subrepresentados de</span>
            <span>&nbsp;los </span>
            <span class="c1">cursos, reuniones </span>
            <span>o entrenamientos ofrecidos por la </span>
            <span class="c1">CONTRATANTE</span>
            <span>&nbsp;para que puedan asistirlos oportunamente y estar presentes para que puedan recibir las </span>
            <span class="c2 c1">orientaciones respectivas de cada curso, reunión o entrenamiento.</span>
          </p>
          <p class="c0">
            <span class="c4 c3 c1">7. EL PLAZO CONTRACTUAL</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 12:</span>
            <span class="c2 c1">
              &nbsp;Este contrato de servicio entra en vigor en la fecha de su firma, y estará en vigor por un plazo
              indefinido.
            </span>
          </p>
          <p class="c0">
            <span class="c4 c3 c1">8. LA RESCISIÓN CONTRACTUAL</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 13:</span>
            <span class="c1">
              &nbsp;La rescisión sin motivo del contrato es facultada a ambas las partes y puede solicitarse con 30
              (treinta) días de antelación, mediante notificación escrita, excepto cuando haya interrupciones no
              sistémicas, gubernamentales o de órganos reguladores y de inspección que puedan cambiar
            </span>
            <span>&nbsp;</span>
            <span class="c1 c10">el modus operandi </span>
            <span class="c1">del</span>
            <span>&nbsp;mercado</span>
            <span class="c2 c1">.</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 14:</span>
            <span class="c2 c1">
              &nbsp;El contrato también puede rescindirse en su totalidad y sin notificación previa, por una buena
              razón, entendida como:
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              Párrafo uno: Unilateralmente, por la parte inocente, en caso de incumplimiento de cualquiera de las
              obligaciones asumidas en este contrato, sin perjuicio
            </span>
            <span>
              &nbsp;de la indemnización, por parte de la parte infractora a los inocentes, por cualquier pérdida y daño
              causado por la parte infractora;
            </span>
          </p>
          <p class="c0">
            <span class="c1 c2">
              Párrafo dos: En el caso de una solicitud, decreto o aprobación de la recuperación judicial o
              extrajudicial, quiebra, liquidación, disolución o situación de insolvencia inequívoca de la otra parte;
            </span>
          </p>
          <p class="c0">
            <span class="c2 c1">Párrafo tres: Si comprobado que sucedió algo fortuito o de fuerza mayor.</span>
          </p>
          <p class="c0">
            <span class="c2 c1">
              Párrafo cuatro: En cualquiera de estos casos de rescisión contractual, se garantiza a las partes el
              acierto de cuentas correcto de los servicios ya prestados hasta la última fecha efectiva de los servicios
              prestados.
            </span>
          </p>
          <p class="c0">
            <span class="c4 c3 c1">9. RESPONSABILIDAD LABORAL</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 15:</span>
            <span class="c1">
              &nbsp;Queda estipulado aquí que no se establece, en virtud del presente contrato, ningún vínculo de empleo
              o responsabilidad entre{" "}
            </span>
            <span>la </span>
            <span class="c1">CONTRATANTE</span>
            <span>&nbsp;</span>
            <span class="c1">y la CONTRATISTA</span>
            <span>&nbsp;y/o </span>
            <span class="c1">sus</span>
            <span>&nbsp;</span>
            <span class="c1">subrepresentados</span>
            <span>&nbsp;para el desempeño de</span>
            <span class="c1">&nbsp;los servicios. La CONTRATISTA</span>
            <span>&nbsp;es </span>
            <span class="c1">única</span>
            <span>
              &nbsp;y exclusiva responsable de todas las obligaciones y cargos derivados de la legislación vigente, ya
              sea laboral, de seguridad social o de cualquier
            </span>
            <span class="c1">&nbsp;otro, obligándose así la CONTRATISTA</span>
            <span>&nbsp;</span>
            <span class="c1">
              a cumplir con las disposiciones legales respecto a la remuneración del trabajo que utiliza y/o
            </span>
            <span>&nbsp;</span>
            <span class="c2 c1">subcontrata, aparte de otras obligaciones y cargos de cualquier naturaleza.</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 16:</span>
            <span class="c2 c1">
              &nbsp;La CONTRATANTE no es responsable de ninguna deuda laboral, fiscal o cualquier otra deuda que tenga
              la Compañía. Estas son su responsabilidad única e intransferible;
            </span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 17:</span>
            <span class="c1">
              &nbsp;Eventuales condenas laborales de la CONTRATANTE, resultantes de la relación legal establecida en el
              presente documento entre las partes, podrán ser descontadas del pago mensual adeudado a la{" "}
            </span>
            <span>CONTRATISTA</span>
            <span class="c1">. Si el valor de las condenas excede las sumas adeudadas por la </span>
            <span>CONTRATISTA</span>
            <span class="c1">,</span>
            <span>&nbsp;</span>
            <span class="c1">la </span>
            <span>CONTRATISTA pagará a la </span>
            <span class="c1">CONTRATANTE</span>
            <span>&nbsp;</span>
            <span class="c2 c1">la diferencia. Los montos también pueden ser descontados de pagos futuros.</span>
          </p>
          <p class="c0">
            <span class="c4 c3 c1">10. RESPONSABILIDAD TRIBUTARIA</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 18:</span>
            <span class="c1">&nbsp;Si a </span>
            <span>la </span>
            <span class="c1">CONTRATANTE</span>
            <span>&nbsp;</span>
            <span class="c1">se le obligan pagar o indemnizar sumas relacionadas a impuestos no pagados por la </span>
            <span>CONTRATISTA</span>
            <span class="c1">, este último estará obligado a reembolsar</span>
            <span>&nbsp;todas </span>
            <span class="c1">las</span>
            <span>&nbsp;</span>
            <span class="c2 c1">
              cantidades pagadas, incluidos los costos procesales y honorarios de abogados, dentro de los 5 días
              posteriores al pago.
            </span>
          </p>
          <p class="c0">
            <span class="c4 c3 c1">11. RESPONSABILIDAD CIVIL</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 19:</span>
            <span class="c1">
              &nbsp;La CONTRATISTA deberé reparar inmediatamente cualquier error, pérdida o daño resultante del
              incumplimiento y/o el cumplimiento de las obligaciones contractuales en desconformidad con el presente
              contrato, obligándose a indemnizar a{" "}
            </span>
            <span>la </span>
            <span class="c1">CONTRATANTE</span>
            <span>&nbsp;</span>
            <span class="c2 c1">o a terceros perjudicados.</span>
          </p>
          <p class="c0">
            <span class="c4 c3 c1">12. CONFIDENCIALIDAD</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 20:</span>
            <span class="c1">
              &nbsp;Las partes contratantes reconocen que el vínculo estipulado en este acuerdo se basa en una relación
              de fidelidad y buena fe mutua. Son conscientes de que, en el desempeño de sus funciones, pueden tener
              acceso a información y materiales que no son conocidos por el público en general y que la parte reveladora
              considera de su propiedad exclusiva y confidencial. Asimismo, en caso de solicitud de cualquier
              información o material confidencial relacionado con las actividades mencionadas en este acuerdo,{" "}
            </span>
            <span>es el único responsable de proporcionar, a su</span>
            <span class="c1">discreción, a la</span>
            <span>&nbsp;empresa </span>
            <span class="c1">CONTRATANTE, eximiendo</span>
            <span>&nbsp;ante las autoridades y otros organismos gubernamentales la responsabilidad de la </span>
            <span class="c1">CONTRATANTE</span>
            <span>&nbsp;de proveer cualquier material o datos de información, teniendo en cuenta el tema de la </span>
            <span class="c2 c1">confidencialidad.</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 21:</span>
            <span class="c2 c1">
              &nbsp;Se prohíbe a las partes utilizar en beneficio propio o de terceros cualquier información
              privilegiada para el desarrollo de una actividad igual o similar a la desarrollada por la otra parte por
              un período igual o superior a cinco (5) años desde el cierre el contrato.
            </span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 22:</span>
            <span class="c2 c1">
              &nbsp;La obligación de sigilo establecida en esta cláusula durará después de la extinción de este contrato
              por un período de diez (10) años.
            </span>
          </p>
          <p class="c0">
            <span class="c1 c3">CLÁUSULA 23:</span>
            <span class="c1">
              &nbsp;Todas las tratativas, negociaciones, contratos, manuales, circulares, notificaciones,
              entrenamientos, reuniones, certificados, documentos contables o cualquier información sobre la actividad
              desarrollada por{" "}
            </span>
            <span>la </span>
            <span class="c1">CONTRATANTE</span>
            <span>&nbsp;</span>
            <span class="c1">son estrictamente confidenciales y no pueden ser divulgados por ningún medio, </span>
            <span>medio de comunicación o bajo </span>
            <span class="c2 c1">
              ninguna justificación, excepto según lo dispuesto por la ley, bajo la penalidad de la aplicación de una
              multa contractual estipulada en este instrumento.
            </span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 24:</span>
            <span class="c1">
              &nbsp;El presente contrato y sus anexos, en su caso, también se convierten en información confidencial y,
              por lo tanto, su existencia y su contenido no pueden ser revelados a terceros, sino mediante autorización
              expresa de{" "}
            </span>
            <span>la </span>
            <span class="c2 c1">CONTRATANTE.</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 25:</span>
            <span class="c1">
              &nbsp;En caso de duda sobre la confidencialidad de una determinada información, la CONTRATISTA la
              mantendrá en absoluto sigilo hasta que{" "}
            </span>
            <span>la </span>
            <span class="c2 c1">CONTRATANTE se manifieste expresamente sobre el tema.</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 26:</span>
            <span class="c2 c1">
              &nbsp;El incumplimiento de las cláusulas de confidencialidad resultará en responsabilidad civil y penal
              por las partes, que podrá resolverse y apurarse en los tribunales.
            </span>
          </p>
          <p class="c0">
            <span class="c4 c3 c1">13. EL JUICIO ARBITRAL</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 27:</span>
            <span class="c1">
              &nbsp;Este acuerdo es aplicable de conformidad con las leyes jurisdiccionales de los países donde se lleva
              a cabo esta transacción y cualquier disputa se resolverá bajo las reglas de arbitraje de ICC Brasil,
            </span>
            <span>&nbsp;</span>
            <span class="c1">a menos que la parte perjudicada tome acciones legales </span>
            <span>en un tribunal de </span>
            <span class="c2 c1">jurisdicción.</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 28:</span>
            <span class="c2 c1">
              &nbsp;Los contratistas reconocen y acuerdan que cualquier disputa en la aplicación de este acuerdo se
              resolverá, en primer lugar, amistosamente. Solo si no es posible, el procedimiento de arbitraje debe ser
              llevado a cabo.
            </span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 29:</span>
            <span class="c2 c1">
              &nbsp;Además de ello, las partes pueden definir el lugar del arbitraje, el idioma, el número de árbitros y
              la forma de costo entre otros.
            </span>
          </p>
          <p class="c0">
            <span class="c4 c3 c1">14. DISPOSICIONES GENERALES</span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 30:</span>
            <span class="c2 c1">
              &nbsp;La tolerancia de cualquiera de las partes respecto al cumplimiento de las obligaciones de la otra
              parte no se considerará una novación o indulto. Las disposiciones de este contrato siguen en pleno vigor,
              como se establece en el presente documento.
            </span>
          </p>
          <p class="c0">
            <span class="c3 c1">CLÁUSULA 31:</span>
            <span class="c2 c1">
              &nbsp;El presente contrato, los derechos y obligaciones que surjan del mismo no podrán ser cedidos,
              transferidos o subrogados por ninguna de las partes sin el consentimiento previo por escrito de las demás
              partes;
            </span>
          </p>
          <p class="c11">
            <span class="c1">
              Y, debido a que por lo tanto son justos y contratados, las partes firman este instrumento de dos copias de
              igual contenido y forma ante los testigos que también subscriben, junto a un profesional abogado
              acreditado con su credencial.
            </span>
          </p>
          <p class="c0">
            <span class="c8">Document version: 2.1</span>
          </p>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default connect(mapStateToProps, actions)(ContractAgentManagerDescriptionES);
