import { Button, CardActions, CardContent } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { createNumberMask } from "redux-form-input-masks";
import * as actions from "../actions";
import CustomInputForm from "../custom/CustomInput";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { CardPageItem } from "../custom/Page";
import { numberNormalizer } from "../util";

// VERIFICADO LCTInputMask - precisou chamar o getConfig.

class ConfigForm extends Component {
  componentDidMount() {
    const { getConfig } = this.props;
    getConfig();
  }

  onSubmit = (formProps) => {
    const { history, updateConfig, showErrorMessage } = this.props;

    formProps.withdrawalFeePercentage = formProps.withdrawalFeePercentage / 100;
    formProps.maxFinePercentage = formProps.maxFinePercentage / 100;

    updateConfig(formProps)
      .then((response) => {
        history.push("/more");
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  render() {
    const { handleSubmit, config } = this.props;
    if (!config.LCTInputMask) return null;
    const percentualMask = createNumberMask({
      suffix: "%",
      decimalPlaces: 2,
      locale: "pt-BR",
      allowNegative: false,
    });
    const lctMask = config.LCTInputMask();
    return (
      <PageContainer>
        <HeaderPageItem title="app.config.title" showBackButton destination="/more" />
        <CardPageItem>
          <CardContent>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <Field
                name="address"
                label="app.company.wallet.address"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />
              <Field
                name="addressUsdtTrx"
                label="app.company.wallet.address.trx"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />
              <Field
                name="addressUsdtEth"
                label="app.company.wallet.address.eth"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />
              <Field
                name="addressUsdtBnb"
                label="app.company.wallet.address.bnb"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />
              <Field
                name="addressEth"
                label="app.company.wallet.address.ether"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />
              <Field
                name="partnerAddress"
                label="app.wallet.address.partner"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
              />
              <Field
                name="minConsultantWithdraw"
                label="app.agent.withdraw.min"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
                {...lctMask}
              />
              <Field
                name="minCustomerWithdraw"
                label="app.customer.withdraw.min"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
                {...lctMask}
              />
              <Field
                name="maxFinePercentage"
                label="app.customer.fine.max"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
                {...percentualMask}
              />
              <Field
                name="withdrawalFeePercentage"
                label="app.customer.withdraw.fee"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
                {...percentualMask}
              />
              <Field
                name="maxWithdrawalFeeAmount"
                label="app.customer.withdraw.fee.max"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
                {...lctMask}
              />
              <Field
                name="redemptionDelay"
                label="app.paymentDelay"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
                normalize={numberNormalizer}
              />

              <Field
                name="startTime"
                label="app.initialWorkHour"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
                type="time"
              />
              <Field
                name="finishTime"
                label="app.finalWorkHour"
                InputLabelProps={{
                  shrink: true,
                }}
                component={CustomInputForm}
                type="time"
              />

              <CardActions>
                <Button fullWidth id="bSubmitOrderForm" variant="contained" color="primary" type="submit">
                  <FormattedMessage id="app.button.save" />
                </Button>
              </CardActions>
            </form>
          </CardContent>
        </CardPageItem>
      </PageContainer>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  if (!values.address) {
    errors.address = "app.wallet.inform";
  } else if (values.address.trim().length == 0) {
    errors.address = "app.wallet.inform";
  }

  if (!values.partnerAddress) {
    errors.partnerAddress = "app.wallet.inform";
  } else if (values.partnerAddress.trim().length == 0) {
    errors.partnerAddress = "app.wallet.inform";
  }

  if (!values.minConsultantWithdraw) {
    errors.minConsultantWithdraw = "app.agent.inform.withdraw.min";
  } else if (values.minConsultantWithdraw <= 0) {
    errors.minConsultantWithdraw = "app.agent.withdraw.greater.zero";
  }

  if (!values.minCustomerWithdraw) {
    errors.minCustomerWithdraw = "app.customer.inform.withdraw.min";
  } else if (values.minCustomerWithdraw <= 0) {
    errors.minCustomerWithdraw = "app.customer.withdraw.greater.zero";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    config: state.config,
    initialValues: state.config,
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "configForm",
    enableReinitialize: true,
    validate,
  })
)(ConfigForm);
