export const faceLivenessDictionary = {
  // use default strings for english
  en: null,
  br: {
    photosensitivyWarningHeadingText: "Aviso de fotossensibilidade",
    photosensitivyWarningBodyText:
      "Esta verificação mostra luzes coloridas. Tenha cuidado se você for fotossensível.",
    goodFitCaptionText: "Bom ajuste",
    tooFarCaptionText: "Longe demais",
    hintCenterFaceText: "Centralize o rosto",
    startScreenBeginCheckText: "Verificar",
    photosensitivyWarningInfoText:
      "Algumas pessoas podem ter convulsões epilépticas quando expostas a luzes piscantes ou coloridas. Se você ou alguém de sua família tem epilepsia, é importante ter cuidado com esse tipo de estímulo.",
    waitingCameraPermissionText:
      "Aguardando autorização para acessar a câmera.",
    hintMoveFaceFrontOfCameraText:
      "Por favor, mova o rosto para a frente da câmera.",
    hintTooManyFacesText:
      "Muitos rostos detectados, por favor, certifique-se de estar sozinho.",
    hintFaceDetectedText: "Rosto detectado com sucesso.",
    hintCanNotIdentifyText: "Não foi possível identificar o rosto.",
    hintTooCloseText: "Muito perto da câmera.",
    hintTooFarText: "Muito longe da câmera.",
    hintConnectingText: "Conectando...",
    hintVerifyingText: "Verificando...",
    hintCheckCompleteText: "Verificação concluída.",
    hintIlluminationTooBrightText: "A iluminação está muito brilhante.",
    hintIlluminationTooDarkText: "A iluminação está muito escura.",
    hintIlluminationNormalText: "Iluminação adequada.",
    hintHoldFaceForFreshnessText: "Permaneça o rosto nessa posição.",
    hintCenterFaceInstructionText: "Centralize o rosto na moldura da câmera.",
    hintFaceOffCenterText: "O rosto está fora do centro.",
    hintMatchIndicatorText: "Indicador de correspondência.",
    cameraMinSpecificationsHeadingText: "Especificações mínimas da câmera",
    cameraMinSpecificationsMessageText:
      "Certifique-se de que sua câmera atenda às especificações mínimas para realizar a verificação.",
    cameraNotFoundHeadingText: "Câmera não encontrada",
    cameraNotFoundMessageText:
      "Não foi possível detectar uma câmera. Certifique-se de que está conectada e tente novamente.",
    retryCameraPermissionsText: "Tentar novamente as permissões da câmera",
    a11yVideoLabelText: "Feed de vídeo ao vivo da câmera",
    goodFitAltText: "Encaixe adequado detectado.",
    photosensitivityWarningBodyText:
      "Este processo pode incluir flashes de luz que podem causar desconforto em pessoas com sensibilidade à luz.",
    photosensitivityWarningHeadingText: "Aviso de sensibilidade à luz",
    photosensitivityWarningInfoText:
      "Se você tiver histórico de fotossensibilidade, prossiga com cautela.",
    tooFarAltText: "Rosto muito distante da câmera.",
    photosensitivityWarningLabelText: "Estou ciente e desejo continuar.",
    recordingIndicatorText: "Gravando...",
    cancelLivenessCheckText: "Cancelar verificação de vivacidade",
    errorLabelText: "Erro",
    connectionTimeoutHeaderText: "Tempo de conexão esgotado",
    connectionTimeoutMessageText:
      "A conexão demorou muito. Verifique sua internet e tente novamente.",
    timeoutHeaderText: "Tempo esgotado",
    timeoutMessageText:
      "O processo demorou mais do que o esperado. Por favor, tente novamente.",
    faceDistanceHeaderText: "Rosto fora de alcance",
    faceDistanceMessageText:
      "Por favor, ajuste a distância do seu rosto até a câmera.",
    multipleFacesHeaderText: "Múltiplos rostos detectados",
    multipleFacesMessageText:
      "Apenas um rosto deve estar visível na câmera. Ajuste sua posição.",
    clientHeaderText: "Erro do cliente",
    clientMessageText:
      "Houve um erro com a solicitação. Verifique os detalhes e tente novamente.",
    serverHeaderText: "Erro do servidor",
    serverMessageText:
      "Houve um erro no servidor. Por favor, tente novamente mais tarde.",
    landscapeHeaderText: "Modo paisagem detectado",
    landscapeMessageText: "Por favor, vire o dispositivo para o modo retrato.",
    portraitMessageText: "Por favor, mantenha o dispositivo em modo retrato.",
    tryAgainText: "Tentar novamente",
  },
  es: {
    photosensitivyWarningHeadingText: "Advertencia de fotosensibilidad",
    photosensitivyWarningBodyText:
      "Esta verificación muestra luces de colores. Tenga cuidado si es fotosensible.",
    goodFitCaptionText: "Buen ajuste",
    tooFarCaptionText: "Demasiado lejos",
    hintCenterFaceText: "Centre la cara",
    startScreenBeginCheckText: "Verificar",
    photosensitivyWarningInfoText:
      "Algunas personas pueden tener convulsiones epilépticas cuando están expuestas a luces parpadeantes o de colores. Si usted o alguien de su familia tiene epilepsia, es importante tener cuidado con este tipo de estímulo.",
    waitingCameraPermissionText:
      "Esperando autorización para acceder a la cámara.",
    hintMoveFaceFrontOfCameraText:
      "Por favor, mova o rosto para a frente da câmera.",
    hintTooManyFacesText:
      "Muitos rostos detectados, por favor, certifique-se de estar sozinho.",
    hintFaceDetectedText: "Rosto detectado com sucesso.",
    hintCanNotIdentifyText: "Não foi possível identificar o rosto.",
    hintTooCloseText: "Muito perto da câmera.",
    hintTooFarText: "Muito longe da câmera.",
    hintConnectingText: "Conectando...",
    hintVerifyingText: "Verificando...",
    hintCheckCompleteText: "Verificação concluída.",
    hintIlluminationTooBrightText: "A iluminação está muito brilhante.",
    hintIlluminationTooDarkText: "A iluminação está muito escura.",
    hintIlluminationNormalText: "Iluminação adequada.",
    hintHoldFaceForFreshnessText: "Segure o rosto por alguns segundos.",
    hintCenterFaceInstructionText: "Centralize o rosto na moldura da câmera.",
    hintFaceOffCenterText: "O rosto está fora do centro.",
    hintMatchIndicatorText: "Indicador de correspondência.",
    cameraMinSpecificationsHeadingText: "Especificaciones mínimas de la cámara",
    cameraMinSpecificationsMessageText:
      "Asegúrese de que su cámara cumpla con las especificaciones mínimas para realizar la verificación.",
    cameraNotFoundHeadingText: "Cámara no encontrada",
    cameraNotFoundMessageText:
      "No se pudo detectar una cámara. Asegúrese de que esté conectada e intente nuevamente.",
    retryCameraPermissionsText: "Volver a intentar permisos de la cámara",
    a11yVideoLabelText: "Transmisión de video en vivo desde la cámara",
    goodFitAltText: "Ajuste adecuado detectado.",
    photosensitivityWarningBodyText:
      "Este proceso puede incluir destellos de luz que podrían causar molestias a personas sensibles a la luz.",
    photosensitivityWarningHeadingText: "Advertencia de fotosensibilidad",
    photosensitivityWarningInfoText:
      "Si tiene antecedentes de fotosensibilidad, proceda con precaución.",
    tooFarAltText: "Rostro demasiado lejos de la cámara.",
    photosensitivityWarningLabelText: "Soy consciente y deseo continuar.",
    recordingIndicatorText: "Grabación en curso",
    cancelLivenessCheckText: "Cancelar verificación de vivacidad",
    errorLabelText: "Error",
    connectionTimeoutHeaderText: "Tiempo de conexión agotado",
    connectionTimeoutMessageText:
      "La conexión tomó demasiado tiempo. Verifique su internet e intente nuevamente.",
    timeoutHeaderText: "Tiempo agotado",
    timeoutMessageText:
      "El proceso tomó más tiempo del esperado. Por favor, intente nuevamente.",
    faceDistanceHeaderText: "Rostro fuera de alcance",
    faceDistanceMessageText: "Ajuste la distancia de su rostro a la cámara.",
    multipleFacesHeaderText: "Se detectaron múltiples rostros",
    multipleFacesMessageText:
      "Solo un rostro debe ser visible en la cámara. Ajuste su posición.",
    clientHeaderText: "Error del cliente",
    clientMessageText:
      "Ocurrió un error con la solicitud. Verifique los detalles e intente nuevamente.",
    serverHeaderText: "Error del servidor",
    serverMessageText:
      "Ocurrió un error en el servidor. Por favor, intente nuevamente más tarde.",
    landscapeHeaderText: "Modo paisaje detectado",
    landscapeMessageText: "Por favor, gire el dispositivo al modo retrato.",
    portraitMessageText: "Por favor, mantenga el dispositivo en modo retrato.",
    tryAgainText: "Intentar nuevamente",
  },
};
