import { getDataFromServer } from "../util";

export const getAdminKyc = (id) =>
  getDataFromServer(
    "GET",
    `/user/kyc/admin?userId=${id}&orderBy=id&sort=desc`,
    null,
    true
  );
export const getPendingKyc = (id) =>
  getDataFromServer("GET", `/user?kyc=PENDING`, null, true);
export const getAnalyzingKyc = (id) =>
  getDataFromServer("GET", `/user?kyc=ANALYZING`, null, true);
export const getKyc = (id) =>
  getDataFromServer("GET", `/user/kyc/${id}`, null, true);
export const updateKyc = (id, formProps) =>
  getDataFromServer("PUT", `/user/kyc/status/${id}`, formProps, true);
export const downloadKyc = (id) =>
  getDataFromServer("GET", `/user/kyc/${id}/download`, null, true);
export const startFaceLivenessSession = () =>
  getDataFromServer("POST", "/user/kyc/start-faceliveness-session", null, true);
export const getFaceLivenessSessionResult = (kycId) =>
  getDataFromServer(
    "GET",
    `/user/kyc/collect-faceliveness-session-data/${kycId}`,
    null,
    true
  );
