import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import alert from "./alert";
import auth from "./auth";
import user from "./user";
import config from "./config";
import contractAgent from "./contractAgent";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    alert,
    auth,
    user,
    config,
    contractAgent,
    form: formReducer,
  });
