import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { getContracts, getUsersBelowMe } from "../actions";
import AbtcAvatar from "../custom/Avatar";
import FixedPageItem from "../custom/FixedPageItem";
import HeaderPageItem from "../custom/HeaderPageItem";
import Number from "../custom/Number";
import PageContainer, { CardPageItem, PageItem } from "../custom/Page";
import { capitalCaseNormalizer, Roles } from "../util";

function AgentCustomerList({ currentUser }) {
  const [loading, setLoading] = useState(true);
  const [loadingFooter, setLoadingFooter] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [agents, setAgents] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customerContracts, setCustomerContracts] = useState([]);
  const [agentsContracts, setAgentsContracts] = useState([]);

  useEffect(() => {
    getUsersBelowMe().then((data) => {
      const directBelowMe = data.filter((u) => u.consultant.id === currentUser.id);
      const myAgents = directBelowMe.filter((u) => u.role === Roles.Agent);
      const myCustomers = directBelowMe.filter((u) => u.role !== Roles.Agent);
      setAgents(myAgents.sort((u1, u2) => compApt(u1, u2) ?? compName(u1, u2)));
      setCustomers(myCustomers.sort((u1, u2) => compApt(u1, u2) ?? compName(u1, u2)));
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!loading) {
      setLoadingFooter(false);
      return;
    }

    if (customers.length > 0) {
      getContracts({ status: "ACTIVE", taxResidence: currentUser.taxResidence }).then((data) => {
        setCustomerContracts(data.filter((c) => c.user.consultantId === currentUser.id));
        setAgentsContracts(data.filter((c) => c.user.consultantId !== currentUser.id && c.user.id !== currentUser.id));
        setLoadingFooter(false);
      });
    }
  }, [customers, loading]);

  if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
  const hasAgents = agents.length !== 0;

  return (
    <PageContainer>
      <HeaderPageItem title="app.navigationBar.users" />

      {hasAgents && (
        <FixedPageItem position="top" height={50}>
          <Tabs variant="fullWidth" indicatorColor="primary" textColor="primary" value={tabIndex} onChange={(event, value) => setTabIndex(value)}>
            <Tab label={<FormattedMessage id="app.agents" />} />
            <Tab label={<FormattedMessage id="app.camel.customers" />} />
          </Tabs>
        </FixedPageItem>
      )}

      <TabContentsGroup value={tabIndex}>
        <TabContents hidden={!hasAgents}>
          <PageItem>
            <UserCardList users={agents} tabIndex={tabIndex} />
          </PageItem>
          <AgentsFooterPageItem users={agents} contracts={agentsContracts} loading={loadingFooter} />
        </TabContents>

        <TabContents>
          <PageItem>
            <PageItem>
              {customers?.length > 0 ? (
                <UserCardList users={customers} tabIndex={tabIndex} />
              ) : (
                <Typography align="center" style={{ marginTop: "12px" }}>
                  <FormattedMessage id="app.user.clients.empty" />
                </Typography>
              )}
            </PageItem>
          </PageItem>
          <CustomersFooterPageItem users={customers} contracts={customerContracts} hasAgents={hasAgents} loading={loadingFooter} />
        </TabContents>
      </TabContentsGroup>
    </PageContainer>
  );
}

const compApt = (u1, u2) => (u1.apt === u2.apt ? null : u1.apt ? -1 : 1);
const compName = (u1, u2) => {
  const n1 = u1.name.toUpperCase();
  const n2 = u2.name.toUpperCase();
  return n1 === n2 ? null : n1 < n2 ? -1 : 1;
};

function TabContentsGroup({ children, value }) {
  children = children.filter((c) => c && c.props && !c.props.hidden);
  if (typeof value !== "number" || value < 0 || value >= children.length) return null;
  return children[value];
}

function TabContents({ children, visible }) {
  return children;
}

function UserCardList({ users, tabIndex }) {
  const [idSearchField, setIdSearchField] = useState("");
  const [nameSearchField, setNameSearchField] = useState("");
  const [loading, setLoading] = useState(false);
  const timerRef = useRef(null);
  let currentApt = null;

  const searchByName = (name) => {
    setIdSearchField("");
    setNameSearchField(name);
  };
  const searchByID = (id) => {
    setNameSearchField("");
    setIdSearchField(id);
  };

  useEffect(() => {
    setIdSearchField("");
    setNameSearchField("");
  }, [tabIndex]);

  const usersFiltered = useMemo(() => {
    setLoading(true);
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setLoading(false);
    }, 1000);
    if (!idSearchField && !nameSearchField) return users;
    if (nameSearchField) return users.filter((u) => u?.name?.toLowerCase().includes(nameSearchField.toLowerCase()));
    if (idSearchField) return users.find((u) => u?.id == idSearchField) ? [users.find((u) => u.id == idSearchField)] : [];
  }, [users, idSearchField, nameSearchField]);

  return (
    <>
      <CardPageItem raised margin={2}>
        <CardContent>
          <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr", gridGap: 8, marginTop: 8 }}>
            <TextField
              id="nameSearchField"
              label={<FormattedMessage id="app.search.nameemail" />}
              variant="outlined"
              size="small"
              value={nameSearchField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => searchByName(e.target.value)}
            />
            <TextField
              id="idSearchField"
              label={<FormattedMessage id="app.search.id" />}
              variant="outlined"
              size="small"
              value={idSearchField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => searchByID(e.target.value)}
            />
          </div>
        </CardContent>
      </CardPageItem>

      {loading ? (
        <>
          <DarkBar />
          <div style={{ display: "flex", justifyContent: "center", margin: "12px 0" }}>
            <CircularProgress />
          </div>
        </>
      ) : (
        <>
          {usersFiltered.length == 0 ? (
            <>
              <DarkBar />
              <Typography align="center" style={{ padding: "12px 0" }}>
                <FormattedMessage id="app.users.not.filter" />
              </Typography>
            </>
          ) : (
            <List>
              {usersFiltered.map((u) => {
                let subHeader = null;
                const apt = u.apt ? "apt" : "inapt";
                if (currentApt !== apt) {
                  currentApt = apt;
                  subHeader = (
                    <DarkBar>
                      <Typography variant="body2" color="textSecondary">
                        <FormattedMessage id={"app." + currentApt} />
                      </Typography>
                    </DarkBar>
                  );
                }
                return (
                  <Fragment key={u.id}>
                    {subHeader}
                    <ListItem key={u.id} button component={Link} to={"/customer/" + u.id}>
                      <ListItemAvatar>
                        <AbtcAvatar imageUrl={u.photoUrl} disableLink />
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${capitalCaseNormalizer(u.name)} (${u.id})`}
                        secondary={
                          <>
                            {u.email}
                            {!u.apt ? (
                              <span style={{ color: "red" }}>
                                {" "}
                                <FormattedMessage id="app.inapt" />
                              </span>
                            ) : (
                              ""
                            )}
                            {u.premium && (
                              <Typography variant="caption" color="primary">
                                {" "}
                                <FormattedMessage id="app.premium" />
                              </Typography>
                            )}
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <ChevronRight />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Fragment>
                );
              })}
            </List>
          )}
        </>
      )}
    </>
  );
}

function DarkBar({ children }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "black",
        height: 32,
        margin: "8px 0 4px 0",
      }}>
      {children}
    </div>
  );
}

function FooterItem({ title, subtitle, variant = "caption", align = "left" }) {
  return (
    <div style={{ display: "flex", flexDirection: "column", textAlign: align }}>
      <Typography variant={variant} color="textPrimary">
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {subtitle}
      </Typography>
    </div>
  );
}

function FixedFooterPageItem({ children, height = 70, loading, style = {} }) {
  return (
    <FixedPageItem elevation={1} height={height} style={{ borderRadius: "10px 10px 0px 0px", padding: 12, ...style }}>
      {loading ? (
        <div style={{ gridColumn: "span 2", textAlign: "center", paddingTop: 12 }}>
          <CircularProgress size={24} />
        </div>
      ) : (
        children
      )}
    </FixedPageItem>
  );
}

function CustomersFooterPageItem({ users, contracts, hasAgents, loading }) {
  const total = users.reduce((acc, u) => ++acc, 0);
  const apt = users.filter((u) => u.apt).reduce((acc, u) => ++acc, 0);
  const numContracts = contracts.reduce((acc, c) => ++acc, 0);
  const portfolio = contracts.reduce((acc, c) => (acc += c.amount), 0);
  return (
    <FixedFooterPageItem loading={loading} style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }} height={hasAgents ? 85 : 70}>
      <FooterItem title={`${total} clientes`} subtitle={`${apt} aptos, ${total - apt} inaptos`} variant="body1" />
      <FooterItem title={`${numContracts} contratos`} subtitle={<Number value={portfolio} currency="LCT" />} variant="body1" align="right" />
      {hasAgents && (
        <Typography variant="caption" color="textSecondary" align="right" style={{ gridColumn: "span 2" }}>
          Inclui contratos de seus agentes
        </Typography>
      )}
    </FixedFooterPageItem>
  );
}

function AgentsFooterPageItem({ users, contracts, loading }) {
  const total = users.reduce((acc, u) => ++acc, 0);
  const apt = users.filter((u) => u.apt).reduce((acc, u) => ++acc, 0);
  const numContracts = contracts.reduce((acc, c) => ++acc, 0);
  const portfolio = contracts.reduce((acc, c) => (acc += c.amount), 0);
  return (
    <FixedFooterPageItem style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }} loading={loading}>
      <FooterItem title={`${total} agentes`} subtitle={`${apt} aptos, ${total - apt} inaptos`} variant="body1" />
      <FooterItem title={`${numContracts} contratos`} subtitle={<Number value={portfolio} currency="LCT" />} variant="body1" align="right" />
    </FixedFooterPageItem>
  );
}

function UserCardList_({ users }) {
  return users.map((u) => (
    <Card key={u.id} style={{ marginTop: 8 }}>
      <CardActionArea component={Link} to={"/customer/" + u.id}>
        <CardHeader
          avatar={<AbtcAvatar imageUrl={u.photoUrl} disableLink />}
          action={
            <IconButton>
              <ChevronRight />
            </IconButton>
          }
          title={`${capitalCaseNormalizer(u.name)} (${u.id})`}
          subheader={
            <>
              {u.email}{" "}
              {!u.apt ? (
                <span style={{ color: "red" }}>
                  <FormattedMessage id="app.inapt" />
                </span>
              ) : (
                ""
              )}
            </>
          }
        />
        {/*
        <CardContent>
          <FormattedMessage id={"app.enuns." + u.role} /> -
          <Number value={u.openContractsAmount} currency="LCT" />{" "}
        </CardContent>
         */}
      </CardActionArea>
    </Card>
  ));
}

export default compose(connect((state) => ({ currentUser: state.user.user.me })))(AgentCustomerList);
