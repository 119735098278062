import React from "react";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FormattedMessage } from "react-intl";
import messages_en from "../../translations/en.json";
import messages_br from "../../translations/br.json";
import messages_es from "../../translations/es.json";
import { connect } from "react-redux";

const UNIT_FONT_SIZE = "60%";
const DARK_FONT_COLOR = "#999";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const messages = {
  en: messages_en,
  br: messages_br,
  es: messages_es,
};

const titleIsLocalizable = (title, user) => user && title && title.startsWith && title.startsWith("app.") && messages[user.locale];

const localizeObjectProps = (obj, user) => {
  if (obj && user) {
    for (const key in obj) {
      const value = obj[key];
      if (typeof value == "object") {
        localizeObjectProps(value, user);
      } else if (titleIsLocalizable(value, user)) {
        obj[key] = messages[user.locale][value];
      }
    }
  }
  return obj;
};

const AbtcMaterialTable = (props) => {
  const newProps = {
    ...props,
    icons: tableIcons,
    title: props.preserveTitle ? props.title : props.title && <FormattedMessage id={props.title} />,
    columns: props.columns.map((c) => ({
      ...c,
      title: titleIsLocalizable(c.title, props.user) ? messages[props.user.locale][c.title] : c.title,
    })),
    localization: localizeObjectProps(props.localization, props.user),
    components: {
      Container: (props) => <div>{props.children}</div>,
    },
  };
  return <MaterialTable {...newProps} />;
};

export default AbtcMaterialTable;

export const TableList = AbtcMaterialTable;

export const newColumn = (title, field, type, align, render, options = {}) => ({
  title,
  field,
  type,
  cellStyle: {
    textAlign: align,
    fontSize: 12,
  },
  headerStyle: {
    textAlignLast: align,
    fontSize: 12,
  },
  render,
  ...options,
});

export const newColumnText = (title, field, render) => newColumn(title, field, "string", "left", render);

export const newColumnDate = (title, field, options) => newColumn(title, field, "date", "center", "", options);

export const newColumnNumeric = (title, field) => newColumn(title, field, "numeric", "right");

export const newColumnStatus = (title, field, names) => newColumn(title, field, "string", "center", (rowData) => names[rowData[field]]);

export const newColumnUSDt = (title, field) =>
  newColumn(title, field, "numeric", "right", (rowData) => (
    <>
      {rowData[field] && rowData[field].toFixed(2)} <span style={{ fontSize: UNIT_FONT_SIZE, color: DARK_FONT_COLOR }}>USDT</span>
    </>
  ));

export const newColumnCOPD = (title, field) =>
  newColumn(title, field, "numeric", "right", (rowData) => (
    <>
      {rowData[field] && rowData[field].toFixed(2)} <span style={{ fontSize: UNIT_FONT_SIZE, color: DARK_FONT_COLOR }}>COPD</span>
    </>
  ));

export const newColumnCLPD = (title, field) =>
  newColumn(title, field, "numeric", "right", (rowData) => (
    <>
      {rowData[field] && rowData[field].toFixed(2)} <span style={{ fontSize: UNIT_FONT_SIZE, color: DARK_FONT_COLOR }}>CLPD</span>
    </>
  ));

export const newColumnBRZ = (title, field) =>
  newColumn(title, field, "numeric", "right", (rowData) => (
    <>
      {rowData[field] && rowData[field].toFixed(2)} <span style={{ fontSize: UNIT_FONT_SIZE, color: DARK_FONT_COLOR }}>BRZ</span>
    </>
  ));

export const newColumnBRL = (title, field) =>
  newColumn(title, field, "numeric", "right", (rowData) => (
    <>
      <span style={{ fontSize: UNIT_FONT_SIZE, color: DARK_FONT_COLOR }}>R$</span> {rowData[field] && rowData[field].toFixed(2)}
    </>
  ));

export const newColumnBTC = (title, field) =>
  newColumn(title, field, "numeric", "right", (rowData) => (
    <>
      {rowData[field] && rowData[field].toFixed(8)} <span style={{ fontSize: UNIT_FONT_SIZE, color: DARK_FONT_COLOR }}>BTC</span>
    </>
  ));

export const newColumnETH = (title, field) =>
  newColumn(title, field, "numeric", "right", (rowData) => (
    <>
      {rowData[field] && rowData[field].toFixed(8)} <span style={{ fontSize: UNIT_FONT_SIZE, color: DARK_FONT_COLOR }}>ETH</span>
    </>
  ));

  export const newColumnGGP = (title, field) =>
    newColumn(title, field, "numeric", "right", (rowData) => (
      <>
        {rowData[field] && rowData[field].toFixed(2)} <span style={{ fontSize: UNIT_FONT_SIZE, color: DARK_FONT_COLOR }}>COPD</span>
      </>
    ));

export const newColumnValue = (title, field) => newColumn(title, field, "numeric", "right", (rowData) => <>{rowData[field] && rowData[field].toFixed(2)}</>);

export const newColumnMoney = (title, field, filterTaxResidence) => {
  if (filterTaxResidence == "Brasil") {
    return newColumnUSDt(title, field);
  } else if (filterTaxResidence == "Colômbia") {
    return newColumnUSDt(title, field);
  } else if (filterTaxResidence == "Chile") {
    return newColumnCLPD(title, field);
  } else if (filterTaxResidence == "Argentina") {
    return newColumnValue(title, field);
  } else if (filterTaxResidence == "Austrália") {
    return newColumnValue(title, field);
  } else if (filterTaxResidence == "Uruguai") {
    return newColumnValue(title, field);
  } else if (filterTaxResidence == "Venezuela") {
    return newColumnUSDt(title, field);
  } else if (filterTaxResidence == "México") {
    return newColumnUSDt(title, field);
  } else if (filterTaxResidence == "Equador") {
    return newColumnUSDt(title, field);
  } else if (filterTaxResidence == "Índia") {
    return newColumnUSDt(title, field);
  } else if (filterTaxResidence == "Nova Zelândia") {
    return newColumnUSDt(title, field);
  } else if (filterTaxResidence == "Panamá") {
    return newColumnUSDt(title, field);
  } else if (filterTaxResidence == "EUA") {
    return newColumnUSDt(title, field);
  } else if (filterTaxResidence == "Costa Rica") {
  return newColumnUSDt(title, field);
  } else if (filterTaxResidence == "Guernsey") {
    return newColumnGGP(title, field);
  } else {
    return newColumn(title, field, "numeric", "right", (rowData) => (
      <>
        {rowData[field] && rowData[field].toFixed(2)} <small style={{ fontSize: UNIT_FONT_SIZE, color: DARK_FONT_COLOR }}>{rowData?.account?.user?.taxCurrency ?? rowData?.user?.taxCurrency}</small>
      </>
    ));
  }
};
