import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { cnpjNormalizer, cpfNormalizer } from "../../util";

class ContractAgentDescriptionBR extends Component {
  state = {
    checked1: false,
    checked2: false,
  };

  render() {
    let { contractUser } = this.props;

    if (this.state.checked1 && this.state.checked2) {
      this.props.validContractAgent(true);
    } else {
      this.props.validContractAgent(false);
    }

    const nome_do_agente = contractUser.name;
    const pessoa_física_juridica = contractUser.type === "NATURAL_PERSON" ? <FormattedMessage id="app.naturalPerson" /> : <FormattedMessage id="app.company" />;
    const cpf_cnpj = contractUser.documentType;
    const numero_cpf_cnpj =
      contractUser.type == "NATURAL_PERSON"
        ? contractUser.taxResidence == "Brasil"
          ? cpfNormalizer(contractUser.documentId)
          : contractUser.documentId
        : contractUser.taxResidence == "Brasil"
        ? cnpjNormalizer(contractUser.documentId)
        : contractUser.documentId;
    const endereco = `${contractUser.address} - ${contractUser.city} / ${contractUser.state} - ${contractUser.country}`;

    return (
      <>
        <div className="doc" style={{ textAlign: "justify" }}>
          <h4>CONTRATO DE PRESTAÇÃO DE SERVIÇO AUTÔNOMO</h4>
          <h4>1. DAS PARTES</h4>
          <p>
            CLÁUSULA 1ª: Lux Capital, LLC., pessoa jurídica de direito privado com sede sito 1603 Capitol Avenue, Ste 219 - Cheyenne, WY 82001, United States, inscrita sob nº 2020-927450,
            doravante denominada CONTRATANTE,
          </p>
          <p>
            CLÁUSULA 2ª: e de outro lado, {nome_do_agente}, {pessoa_física_juridica} com {cpf_cnpj} {numero_cpf_cnpj}, residente na {endereco}, neste instrumento denominada CONTRATADA, decidem por
            firmar contrato de prestação de serviços autônomos regidos pelas cláusulas abaixo acordadas:
          </p>
          <h4>2. DO OBJETO</h4>
          <p>
            CLÁUSULA 3ª: É objeto do presente contrato a prestação de serviços a ser realizado pela CONTRATADA em prol da CONTRATANTE, exercida pela divulgação e apresentação à clientes, demonstrando
            os serviços que estão disponíveis pela CONTRATANTE, dentro da sua capacidade empresarial nos termos jurídicos por ela disposta, tratando-se exclusivamente de promoção de tornar conhecido e
            de como fazer uso dos serviços oferecidos pela CONTRATANTE.
          </p>
          <p>
            CLÁUSULA 4ª: A prestação do serviço objeto deste instrumento será executada na região determinada pela CONTRATADA, a qual deve ser informada à CONTRATANTE quanto a escolha do local de
            atuação;
          </p>
          <h4>3. DO PAGAMENTO</h4>
          <p>
            CLÁUSULA 5ª: Pelo cumprimento do objeto deste instrumento, identificado na Cláusula 3ª, a CONTRATADA receberá, mensalmente, uma porcentagem do volume custodiado de cada LCContract ativo
            sob sua responsabilidade.
          </p>
          <p>Parágrafo Primeiro: o volume custodiado de um LCContract corresponde ao valor aportado pelo cliente no momento da abertura do mesmo, conforme descrito nos TERMOS E CONDIÇÕES DE USO.</p>
          <p>
            Parágrafo Segundo: O valor aportado pelo cliente em um LCContract é oriundo de decisão pessoal, autônoma e individual dele, que assim deseja aderir aos serviços disponibilizados pela
            CONTRATANTE.
          </p>
          <p>
            Parágrafo Terceiro: um LCContract é considerado ativo no período entre sua data de ativação e data de término, ou seja, o período durante o qual está gerando acréscimo de volume para o
            cliente.
          </p>
          <p>O valor da porcentagem varia de acordo com o tipo de LCContract, onde contratos de maior duração e fechados (vide os TERMOS E CONDIÇÕES DE USO para detalhes) geram um valor maior.</p>
          <p>
            CLÁUSULA 6ª: Os pagamentos referentes à prestação de serviço realizados serão efetuados por meio de crédito na conta da CONTRATADA na plataforma. Os valores referentes ao comissionamento
            da prestação de serviço serão pagos na stable coin determinada conforme o país do cadastro da CONTRATADA.
          </p>
          <p>CLÁUSULA 7ª: O pagamento do comissionamento ocorrerá no dia de aniversário do LCContract ao qual se refere.</p>
          <p>Parágrafo Único: O dia de aniversário de um LCContract é o dia em que ele foi ativado, contabilizando um aniversário a cada mês.</p>
          <p>CLÁUSULA 8ª: O saque dos valores da conta na plataforma deverá ser feito conforme regras válidas para todos usuários da mesma, conforme descrito nos TERMOS E CONDIÇÕES DE USO.</p>
          <h4>4. DAS OBRIGAÇÕES DA CONTRATANTE</h4>
          <p>CLÁUSULA 9ª: Fica obrigada a contratante a:</p>
          <p>a) fornecer informação, esclarecimentos e capacitação para o bom desenvolvimento das atividades objeto deste ato contratual;</p>
          <p>b) fornecer suporte técnico necessário para o seguimento da atividade objeto deste contrato;</p>
          <p>c) fiscalizar a atividade da CONTRATADA e propiciar que a mesma desenvolva às atividades de acordo com as normativas e orientações proporcionada da CONTRATANTE;</p>
          <p>d) realizar o pagamento dos serviços prestados observado o disposto nas cláusulas 5ª a 7ª do presente termo.</p>
          <p>
            e) avisar previamente a CONTRATADA das reuniões, cursos ou treinamentos a serem realizados pela CONTRATANTE ou por quem esta, exclusivamente, indicar apta(o) para tal, com antecedência de
            5 dias.
          </p>
          <h4>5. DAS OBRIGAÇÕES DA CONTRATADA</h4>
          <p>CLÁUSULA 10ª: Fica obrigada a contratada à:</p>
          <p>
            a) realizar a prestação de serviço objeto deste contrato de acordo com as normas, informações e capacitação proporcionada pela CONTRATANTE, ou por quem esta, exclusivamente, indicar
            apta(o) para tal;
          </p>
          <p>b) exercer, de maneira personalíssima, a prestação de serviço objeto deste contrato;</p>
          <p>c) prestar de maneira clara e objetiva, informações e orientações aos clientes sobre a funcionalidade e serviços da empresa CONTRATANTE;</p>
          <p>d) fornecer, informar, preencher adequadamente à plataforma os dados dos respectivos clientes à empresa CONTRATANTE.</p>
          <p>e) fornecer à CONTRATANTE, sempre que solicitado, no prazo de 48 horas, as informações referente à prestação de serviços deste contrato.</p>
          <p>f) participar das reuniões, cursos ou treinamentos proporcionados pela empresa CONTRATANTE, previamente agendados, ou por quem esta, exclusivamente, indicar apta(o) para tal.</p>
          <p>
            g) arcar com os custos de deslocamento, estadias, transportes, seguros ou quaisquer outras despesas necessárias para o atendimento aos seus clientes, bem como ao comparecimento às
            reuniões, cursos e treinamentos previamente agendados pela CONTRATANTE, ou por quem esta, exclusivamente, indicar apta(o) para tal.
          </p>
          <p>h) responder adequadamente a declaração dos tributos fiscais dos valores recebidos em prol da prestação de serviço efetuada.</p>
          <h4>6. DA VIGÊNCIA CONTRATUAL</h4>
          <p>CLÁUSULA 11ª: O presente contrato de prestação de serviço passa a vigorar na data de sua assinatura, passando a ser vigente por prazo indeterminado.</p>
          <h4>7. DA RESCISÃO CONTRATUAL</h4>
          <p>
            CLÁUSULA 12ª: A rescisão imotivada do contrato é facultada por ambas as partes e pode ser solicitada com aviso prévio de 30 (trinta) dias, por meio de notificação escrita, salvo quando
            interrupções não sistêmicas, governamentais ou de órgãos regulamentadores e fiscalizadores, que por ventura possam vir a alterar o modus operandi do mercado em questão.
          </p>
          <p>CLÁUSULA 13ª: O contrato poderá ainda ser rescindido de pleno direito e sem prévia notificação, por justo motivo, este entendido como:</p>
          <p>
            Parágrafo Primeiro: Unilateralmente, pela parte inocente, na hipótese de descumprimento de qualquer das obrigações assumidas neste contrato, sem prejuízo da indenização, pela parte
            infratora à inocente, pelas eventuais perdas e danos a que a parte infratora der causa;
          </p>
          <p>
            Parágrafo Segundo: No caso de pedido, decretação ou homologação de recuperação judicial ou extrajudicial, falência, liquidação, dissolução ou inequívoca situação de insolvência da outra
            parte;
          </p>
          <p>Parágrafo Terceiro: Comprovada a ocorrência de caso fortuito ou força maior.</p>
          <p>
            Parágrafo Quarto: Em quaisquer dessas hipóteses de rescisão contratual, é garantido às partes o acerto de contas pelos serviços já prestados, até a última data efetiva de serviços
            prestados.
          </p>
          <h4>8. DA RESPONSABILIDADE TRABALHISTA</h4>
          <p>
            CLÁUSULA 14ª: Fica estipulado neste, que não se estabelece, por força do presente contrato, qualquer vínculo de natureza empregatícia ou de responsabilidade entre o CONTRATANTE e
            CONTRATADA para a execução dos serviços, sendo a CONTRATADA única e exclusiva responsável por todas as obrigações e encargos decorrentes da legislação vigente, seja a trabalhista,
            previdenciária ou qualquer outra, obrigando-se, assim a CONTRATADA ao cumprimento das disposições legais no que se refere à remuneração da mão de obra que utilizar e a outras obrigações e
            encargos de qualquer natureza.
          </p>
          <p>
            CLÁUSULA 15ª: A CONTRATANTE não se responsabiliza por quaisquer dívidas trabalhistas, tributárias ou quaisquer outras que possua a CONTRATADA. Estas são de sua exclusiva e intransferível
            responsabilidade;
          </p>
          <p>
            CLÁUSULA 16ª: Eventuais condenações trabalhistas da CONTRATADA advindas da relação jurídica aqui estabelecida entre as partes, poderão ser descontadas do pagamento mensal devido à
            CONTRATADA. Caso o valor das condenações supere as importâncias devidas pela, a CONTRATADA ressarcirá a CONTRATANTE no valor da diferença, podendo ainda as verbas serem descontadas de
            pagamentos futuros.
          </p>
          <h4>9. DA RESPONSABILIDADE TRIBUTÁRIA</h4>
          <p>
            CLÁUSULA 17ª: Caso a CONTRATANTE seja obrigado a pagar ou indenizar importância referente a tributos não pagos pela CONTRATADA, ficará este último obrigado a ressarcir todos valores
            despendidos, incluindo eventuais custas processuais e honorários advocatícios, no prazo de até 5 dias do efetivo pagamento.
          </p>
          <h4>10. DA RESPONSABILIDADE CIVIL</h4>
          <p>
            CLÁUSULA 18ª: A CONTRATADO deverá reparar imediatamente qualquer erro, perda ou dano resultante do descumprimento e/ou do cumprimento das obrigações contratuais em desconformidade com o
            presente contrato, obrigando-se a ressarcir a CONTRATANTE ou terceiros prejudicados
          </p>
          <h4>11. DA CONFIDENCIALIDADE</h4>
          <p>
            CLÁUSULA 19ª: As partes contratantes reconhecem que o vínculo estipulado neste contrato é baseado em relação de fidelidade e boa-fé recíproca e ficam a partir cientes que, no exercício de
            suas funções, poderão ter acesso a informações e materiais que não são de conhecimento do público em geral e que a parte reveladora considera ser de sua propriedade exclusiva e de caráter
            confidencial e, em caso de solicitação de algum material informativo ou confidencial relacionado com as atividades mencionadas neste contrato, fica com exclusiva responsabilidade de
            fornecer, a seu critério, a empresa CONTRATANTE, eximindo perante às autoridades e demais órgãos governamentais a responsabilidade da CONTRATADA em fornecer qualquer material ou dado
            informativo, tendo em vista se tratar de assunto de confidencialidade.
          </p>
          <p>
            CLÁUSULA 20ª: É vedado a qualquer das partes usar em benefício próprio ou de terceiros quaisquer informações privilegiadas para o desenvolvimento de atividade igual ou similar à
            desenvolvida pela outra parte, pelo período igual ou maior de 5 (cinco) anos, quando da resolução do contrato.
          </p>
          <p>CLÁUSULA 21ª: A obrigação de sigilo estabelecida nessa cláusula perdurará após a extinção deste contrato, pelo período de 10 (dez) anos.</p>
          <p>
            CLÁUSULA 22ª: Todas as tratativas, negociações, contratos, manuais, circulares, notificações, treinamentos, reuniões, certidões, documentos contábeis, ou quaisquer informações a respeito
            da atividade desenvolvida pela CONTRATANTE são estritamente confidenciais, não podendo ser divulgados por qualquer meio, mídia ou sob qualquer justificativa, com exceção das previstas em
            Lei, sob pena de aplicação de multa contratual, estipulada no presente instrumento.
          </p>
          <p>
            CLÁUSULA 23ª: O presente contrato e seus anexos, se houver, tornam-se também informação confidencial e por isso a sua existência e seu conteúdo não poderão ser revelados a terceiros, senão
            mediante autorização expressa da CONTRATANTE.
          </p>
          <p>
            CLÁUSULA 24ª: Em caso de dúvida sobre a confidencialidade de determinada informação, a CONTRATADA deverá mantê-la em absoluto sigilo até que o CONTRATANTE se manifeste expressamente a
            respeito.
          </p>
          <p>CLÁUSULA 25ª: A não observância das cláusulas de confidencialidade importará em responsabilidade cível e criminal pelas partes, as quais poderão ser dirimidas e apuradas em Juízo.</p>
          <h4>12. DO JUÍZO ARBITRAL</h4>
          <p>
            CLÁUSULA 26ª: Este contrato é aplicável de acordo com as leis de jurisdição dos países onde essa transação é realizada e qualquer disputa deverá ser resolvida sob as regras da ICC Brasil
            para arbitragem, a menos que a parte lesada tome medidas legais em um tribunal de jurisdição.
          </p>
          <p>
            CLÁUSULA 27ª: Os contratantes reconhecem e concordam que qualquer divergência disputa na aplicação deste o acordo será resolvido, primeiramente amigavelmente, salvo se não for possível, o
            procedimento de arbitragem deve ser seguido.
          </p>
          <p>CLÁUSULA 28ª: Adicionalmente as partes podem definir a sede da arbitragem, o idioma, o número de árbitros, a forma de custeio, dentre outros.</p>
          <h4>13. DAS DISPOSIÇÕES GERAIS</h4>
          <p>
            CLÁUSULA 29ª: A tolerância por qualquer uma das partes, no que tange ao cumprimento das obrigações da outra parte, não será considerada novação ou perdão, permanecendo as cláusulas deste
            contrato em pleno vigor, da forma aqui prevista.
          </p>
          <p>
            CLÁUSULA 30ª: O presente contrato e os direitos e obrigações dele decorrentes não poderão ser cedidos, transferidos ou sub-rogados por quaisquer das partes sem o prévio consentimento por
            escrito das outras partes;
          </p>
          <p>
            E por estarem assim justas e contratadas, as partes assinam o presente instrumento em duas vias de igual teor e forma, perante as testemunhas também abaixo assinadas, juntamente com
            profissional advogado identificado juntamente com sua credencial.
          </p>
          <p>Document version: 2.1</p>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default connect(mapStateToProps, actions)(ContractAgentDescriptionBR);
