import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import App from "./App";
import configureStore, { history } from "./configStore";
import * as serviceWorker from "./serviceWorker";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";

const { store } = configureStore();

const amplifyOutputs_ = {
  auth: {
    user_pool_id: process.env.REACT_APP_USER_POOL_ID,
    aws_region: process.env.REACT_APP_AWS_REGION,
    user_pool_client_id: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    identity_pool_id: process.env.REACT_APP_IDENTITY_POOL_ID,
    unauthenticated_identities_enabled: true,
  },
  version: "1.1",
};
Amplify.configure(amplifyOutputs_);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App history={history} />
    </ConnectedRouter>
  </Provider>,
  document.querySelector("#root")
);

serviceWorker.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        if (event.target.state == "activated") {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});
