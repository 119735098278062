import { Button, Card, CardActions, CardContent, Checkbox, CircularProgress, FormControlLabel } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import * as actions from "../actions";
import { getAddressInfo } from "../actions";
import performRequest from "../configAxios";
import CustomInputForm, { CustomDateOfBirthPicker } from "../custom/CustomInput";
import { default as CustomSelect, default as customSelect } from "../custom/CustomSelect";
import HeaderPageItem from "../custom/HeaderPageItem";
import PageContainer, { PageItem } from "../custom/Page";
import { docNormalizer, numberNormalizer, phoneNormalizer } from "../util";
import { country } from "./components/Terms";
import { CustomView, isIOS } from "react-device-detect";

function FormCheckbox({ input, label, ...props }) {
  return <Checkbox label={label} checked={input.value ? true : false} onChange={input.onChange} {...props} />;
}

class AdminUserFormData extends Component {
  state = {
    loading: true,
    user: {},
    typePf: true,
    doc: "CPF",
  };

  componentDidMount() {
    const {
      initialize,
      history,
      currentUser,
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      performRequest("GET", `/user/${id}`, null, true)
        .then((response) => {
          const user = response.data.data;
          if (user.role == "ADMIN" && currentUser.role !== "ADMIN") {
            history.push("/notfound");
          }
          if (currentUser.role == "BUSINESS_MANAGER" && user.role != "CUSTOMER" && user.role != "AGENT") {
            history.push("/notfound");
          }
          this.setState({ loading: false, user });

          initialize(user);
        })
        .catch((e) => {
          history.push("/notfound");
        });
    } else {
      this.setState({ loading: false });
    }
  }

  onSubmit = (formProps) => {
    const { showErrorMessage, showSuccessMessage, history } = this.props;
    let data = formProps;

    if (data.documentType == "CERTIFICATE_INCORPORATION" || data.documentType == "CNPJ") {
      data.type = "COMPANY";
    } else {
      data.type = "NATURAL_PERSON";
    }
    let method = data.id ? "PUT" : "POST";
    let url = "/user/" + (data.id ? data.id : "");
    if (!data.id) {
      delete data.id;
    }
    performRequest(method, url, data, true)
      .then((response) => {
        if (method == "POST") {
          showSuccessMessage("app.register.success");
          history.push("/user/" + this.state.user.id);
        } else if (method == "PUT") {
          showSuccessMessage("app.edit.sucesfully");
          history.push("/user/" + this.state.user.id);
        }
      })
      .catch((e) => {
        showErrorMessage(e.response ? e.response.data.message : e);
      });
  };

  verifyCep = (cep) => {
    if (cep.length == 8) {
      getAddressInfo(cep.replace(/[^A-Za-z0-9]/gi, ""))
        .then((data) => {
          if (!data.erro) {
            this.props.change("address", data.logradouro + " - " + data.bairro);
            this.props.change("city", data.localidade);
            this.props.change("state", data.uf);
            this.props.change("country", "Brasil");
          }
        })
        .catch((e) => {});
    }
  };

  onChange = (value) => {
    this.setState({ doc: value });
  };

  render() {
    const { handleSubmit, initialized, currentUser } = this.props;
    const { loading } = this.state;

    const phoneOnChange = (fieldName, name, value) => {
      // Esta rotina opera sobre o nome de campo recebido em fieldName, que será "formIsWhats" ou "formIsWhats2".
      // Determina se o campo deve ficar desabilitado (com base no valor ser vazio).
      let disabled = value == "";
      // Muda a variável no estado (é o nome do campo + "Disabled").
      this.setState({ [fieldName + "Disabled"]: disabled });
      // Muda o valor do campo no Redux Forms.
      if (disabled) {
        this.props.change(fieldName, false);
        this.setState({ [fieldName]: false });
      }
      this.setState({ [name]: value });
      this.props.change(name, value);
    };

    const adminRoles = [
      { value: "CUSTOMER", input: "app.enuns.CUSTOMER" },
      { value: "AGENT", input: "app.enuns.AGENT" },
      { value: "KYC", input: "app.enuns.KYC" },
      { value: "FINANCE_MANAGER", input: "app.enuns.FINANCE_MANAGER" },
      { value: "ADMIN", input: "app.enuns.ADMIN" },
    ];
    const businessRoles = [
      { value: "CUSTOMER", input: "app.enuns.CUSTOMER" },
      { value: "AGENT", input: "app.enuns.AGENT" },
    ];

    /*
      Aqui deve ser informado um documento de identificação tributária (não é identificação pessoal).
      Por isso não aceita carteia de identidade ou contrato social.
      Talvez não deve aceitar passaporte, mas a receita aceita esse documento na declaração 1888, então ele 
      existe como possibilidade aqui.
      OBS.: não é possível se cadastrar usando passaporte.
      O cliente que desejar usar esse documento precisará, na hora do cadastro, escolher CPF ou NIT
      e cadastrar um número qualquer. Depois, durante o KYC, enviar o passaporte como anexo e pedir para 
      que ele seja usado para fins tributários.
    */
    const documentType = [
      { value: "CPF", input: "app.cpf" },
      { value: "CNPJ", input: "app.cnpj" },
      { value: "NIT", input: "app.nit" },
      { value: "CC", input: "app.cc" },
      { value: "NIF", input: "app.nif" },
      { value: "PASSPORT", input: "app.passport" },
      { value: "GSTIN", input: "app.gstin" },
      { value: "RUC", input: "app.ruc" },
      { value: "SSN", input: "app.ssn" },
      { value: "CI", input: "app.ci" },
      { value: "NIC", input: "app.nic" },
      { value: "SIN", input: "app.sin" },
      { value: "GCRN", input: "app.gcrn" },
    ];

    if (loading) return <CircularProgress style={{ marginTop: "25%" }} />;
    return (
      <PageContainer>
        <PageItem>
          <HeaderPageItem title={initialized ? "app.user.edit" : "app.addUser"} showBackButton={true} destination={initialized ? "/user/" + this.props.match.params.id : "/users"} />
        </PageItem>
        <PageItem>
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <Field
                  name="name"
                  label="app.name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={CustomInputForm}
                  disabled={currentUser.role !== "ADMIN" && currentUser.role !== "KYC"}
                />
                <Field
                  name="email"
                  label="app.email"
                  type="email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={CustomInputForm}
                  disabled={currentUser.role !== "ADMIN" && currentUser.role !== "KYC"}
                />
                {!this.state.user.isPj && (
                  <>
                    <CustomView condition={isIOS}>
                      <Field name="dateOfBirth" label="app.dateOfBirth" component={CustomInputForm} InputLabelProps={{ shrink: true }} type="date" />
                    </CustomView>
                    <CustomView condition={!isIOS}>
                      <Field name="dateOfBirth" label="app.dateOfBirth" component={CustomDateOfBirthPicker} InputLabelProps={{ shrink: true }} />
                    </CustomView>
                  </>
                )}

                <Field
                  name="taxResidence"
                  label="app.taxResidence"
                  component={customSelect}
                  values={country}
                  InputLabelProps={{ shrink: true }}
                  disabled={currentUser.role !== "ADMIN" && currentUser.role !== "KYC"}
                />
                <Field
                  name="documentType"
                  label="app.documentType"
                  component={customSelect}
                  values={documentType}
                  InputLabelProps={{ shrink: true }}
                  disabled={currentUser.role !== "ADMIN" && currentUser.role !== "KYC"}
                />

                {this.state.user.isPj ? (
                  <>
                    <Field
                      name="documentId"
                      label="app.documentId"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      component={CustomInputForm}
                      normalize={docNormalizer}
                      onChange={(e) => {
                        this.props.change("cnpj", e.target.value);
                      }}
                      disabled={currentUser.role !== "ADMIN" && currentUser.role !== "KYC"}
                    />
                    <Field
                      name="legalRepresentativeId"
                      label={this.state.user.taxResidence == "Brasil" ? "app.legalRepresentative.cpf" : "app.legalRepresentativeDocumentId"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      component={CustomInputForm}
                      disabled={currentUser.role !== "ADMIN" && currentUser.role !== "KYC"}
                    />
                    <Field
                      name="legalRepresentativeName"
                      label="app.representative.name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      component={CustomInputForm}
                      disabled={currentUser.role !== "ADMIN" && currentUser.role !== "KYC"}
                    />
                  </>
                ) : (
                  <Field
                    name="documentId"
                    label="app.documentId"
                    component={CustomInputForm}
                    normalize={docNormalizer}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      this.props.change("cpf", e.target.value);
                    }}
                    disabled={currentUser.role !== "ADMIN" && currentUser.role !== "KYC"}
                  />
                )}
                <Field
                  name="zipCode"
                  label="app.zipCode"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 17,
                  }}
                  component={CustomInputForm}
                  onChange={(e) => this.verifyCep(e.target.value)}
                  disabled={currentUser.role !== "ADMIN" && currentUser.role !== "KYC"}
                />
                <Field
                  name="address"
                  label="app.address"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={CustomInputForm}
                  disabled={currentUser.role !== "ADMIN" && currentUser.role !== "KYC"}
                />
                <Field
                  name="city"
                  label="app.city"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={CustomInputForm}
                  disabled={currentUser.role !== "ADMIN" && currentUser.role !== "KYC"}
                />
                <Field
                  name="state"
                  label="app.state"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={CustomInputForm}
                  disabled={currentUser.role !== "ADMIN" && currentUser.role !== "KYC"}
                />
                <Field
                  name="country"
                  label="app.country"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  component={CustomInputForm}
                  disabled={currentUser.role !== "ADMIN" && currentUser.role !== "KYC"}
                />
                <Field
                  name="telefone"
                  label="app.phone"
                  component={CustomInputForm}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => phoneOnChange("isWhats", "telefone", e.value)}
                  normalize={phoneNormalizer}
                  disabled={currentUser.role !== "ADMIN" && currentUser.role !== "KYC"}
                />
                <FormControlLabel
                  disabled={this.state.formIsWhatsDisabled || (currentUser.role !== "ADMIN" && currentUser.role !== "KYC")}
                  label={<FormattedMessage id="app.whatsapp" />}
                  control={<Field component={FormCheckbox} name="isWhats" color="primary" />}
                />
                <Field
                  name="telefone2"
                  label="app.phone"
                  component={CustomInputForm}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => phoneOnChange("isWhats2", "telefone2", e.value)}
                  normalize={phoneNormalizer}
                  disabled={currentUser.role !== "ADMIN" && currentUser.role !== "KYC"}
                />
                <FormControlLabel
                  disabled={this.state.formIsWhats2Disabled || (currentUser.role !== "ADMIN" && currentUser.role !== "KYC")}
                  label={<FormattedMessage id="app.whatsapp" />}
                  control={<Field component={FormCheckbox} name="isWhats2" color="primary" />}
                />
                <Field
                  name="role"
                  label="app.role"
                  component={CustomSelect}
                  values={currentUser.role == "ADMIN" ? adminRoles : businessRoles}
                  disabled={currentUser.role !== "ADMIN" && currentUser.role !== "BUSINESS_MANAGER"}
                />

                <FormControlLabel
                  disabled={currentUser.role !== "ADMIN" && currentUser.role !== "KYC"}
                  label={<FormattedMessage id="app.premium" />}
                  control={<Field component={FormCheckbox} name="premium" color="primary" />}
                />

                <Field name="observations" label="app.obs" component={CustomInputForm} multiline />

                <CardActions>
                  <Button fullWidth id="bSubmitOrderForm" variant="contained" color="primary" type="submit" disabled={this.props.pristine || this.props.submitting || this.props.invalid}>
                    <FormattedMessage id={initialized ? "app.user.edit" : "app.button.save"} />
                  </Button>
                </CardActions>
              </form>
            </CardContent>
          </Card>
        </PageItem>
      </PageContainer>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "app.form.name.error";
  } else if (values.name.trim().length == 0) {
    errors.name = "app.name.invalid";
  }
  if (!values.email) {
    errors.email = "app.form.email.error";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+$/i.test(values.email)) {
    errors.email = "app.form.email.invalid";
  }
  if (!values.documentId) {
    errors.documentId = "app.form.documentId.error";
  } else if (values.documentId.trim().length == 0) {
    errors.documentId = "app.documentId.invalid";
  }
  // if (!values.zipCode) {
  //   errors.zipCode = "app.form.zipcode.error";
  // } else if (values.zipCode.trim().length == 0) {
  //   errors.zipCode = "app.zipcode.invalid";
  // }
  // if (!values.address) {
  //   errors.address = "app.form.address.error";
  // } else if (values.address.trim().length == 0) {
  //   errors.address = "app.address.invalid";
  // }
  // if (!values.city) {
  //   errors.city = "app.form.city.error";
  // } else if (values.city.trim().length == 0) {
  //   errors.city = "app.city.invalid";
  // }
  // if (!values.state) {
  //   errors.state = "app.form.state.error";
  // } else if (values.state.trim().length == 0) {
  //   errors.state = "app.form.state.error";
  // }
  // if (!values.country) {
  //   errors.country = "app.form.country.error";
  // } else if (values.country.trim().length == 0) {
  //   errors.country = "app.country.invalid";
  // }
  // if (!values.telefone) {
  //   errors.telefone = "app.form.phone.error";
  // } else if (values.telefone.trim().length == 0) {
  //   errors.telefone = "app.phone.invalid";
  // }

  if (!values.role) {
    errors.role = "app.role.error";
  } else if (values.role.trim().length == 0) {
    errors.role = "app.role.invalid";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "adminUserForm",
    validate,
  })
)(AdminUserFormData);
