import { createNumberMask } from "redux-form-input-masks";
import performRequest from "../configAxios";
import { getDataFromServer } from "../util";
import * as alerts from "./Alert";
import { GET_CONFIG, UPDATE_CONFIG } from "./types";

const lctCodeByTaxResidence = {
  Argentina: "USDt",
  Austrália: "USDt",
  Brasil: "BRZ",
  Colômbia: "USDt",
  ["Costa Rica"]: "USDt",
  Guernsey: "USDt",
  Uruguai: "USDt",
  Portugal: "EURD",
  Chile: "CLPD",
  Venezuela: "USDt",
  México: "USDt",
  Equador: "USDt",
  Índia: "USDt",
  ["Nova Zelândia"]: "USDt",
  Panamá: "USDt",
  EUA: "USDt",
};

export const getLCTCodeByTaxResidence = (taxResidence) => lctCodeByTaxResidence[taxResidence] ?? "*";

export const getConfig2 = () => {
  return new Promise(async (resolve, reject) => {
    await getDataFromServer("GET", "/config", null, true)
      .then((data) => {
        data.LCTCode = "LCT";
        data.LCTFractionDigits = 4;
        resolve(data);
      })
      .catch((e) => reject(e));
  });
};

export const getConfig = () => async (dispatch, getState) => {
  const state = getState();
  await performRequest("GET", "config", null, true)
    .then((response) => {
      let config = response.data.data;
      config.withdrawalFeePercentage = config.withdrawalFeePercentage * 100;
      config.maxFinePercentage = config.maxFinePercentage * 100;
      config.LCTCode = lctCodeByTaxResidence[state.user.user.me.taxResidence];
      config.LCTFractionDigits = 2;
      config.LCTInputMask = (onChange, allowNegative = false) => createLCTInputMask(onChange, config, allowNegative);
      dispatch({ type: GET_CONFIG, payload: config });
    })
    .catch((e) => {
      Promise.reject(e);
    });
};

export const createLCTInputMask = (onChange, config, allowNegative = false) => {
  return createNumberMask({
    suffix: " " + config.LCTCode + " ",
    decimalPlaces: config.LCTFractionDigits,
    locale: "pt-BR",
    allowNegative,
    onChange,
  });
};

export const updateConfig = (formProps) => async (dispatch) => {
  await performRequest("PUT", "config", formProps, true)
    .then((response) => {
      dispatch({ type: UPDATE_CONFIG, payload: response.data.data });
      dispatch(alerts.showSuccessMessage("app.update"));
      Promise.resolve(response);
    })
    .catch((e) => {
      Promise.reject(e);
    });
};
export const getOperation = (startDate, finalDate) => getDataFromServer("GET", `/operation?startDate=${startDate}&finalDate=${finalDate}`, null, true);
export const getAllOperations = () => getDataFromServer("GET", `/operation`, null, true);
export const getCurrentOperations = () => getDataFromServer("GET", `/operation/current`, null, true);
export const infoOperation = (params) => getDataFromServer("POST", "/operation", params, true);
export const editOperation = (id, params) => getDataFromServer("PUT", "/operation/" + id, params, true);
export const delOperation = (id) => getDataFromServer("DELETE", "/operation/" + id, null, true);
